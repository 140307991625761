











































































































































import Vue from 'vue';
import User from '@glittr/frontend-core/src/plugins/auth/user';
import MembershipModel from '../../services/viewModel/resource/MembershipResourceViewModel';

export default Vue.extend({
  auth: true,
  props: {
    hasBackButton: { type: Boolean, default: false },
  },
  data: () => ({
    membership: undefined as MembershipModel | undefined,
    profileMenuVisible: false,
    isSidedrawerVisible: true,
    isMembershipDataLoading: false,
  }),
  computed: {
    user(): User {
      return this.$auth.user ?? ({} as User);
    },
    isAdmin(): boolean {
      let isAdmin = false;
      if (this.$auth.isLoggedIn) {
        isAdmin = this.$auth.isInRole('Admin');
      }
      return isAdmin;
    },
    isPwaButtonVisible(): boolean {
      return !!Vue.$store.values.showPwaInstallPromptFunction;
    },
    username(): string {
      if (this.isMembershipDataLoading) {
        return '…';
      }
      if (this.hasMembership && this.membership) {
        const owner = this.membership.memberContact;
        if (owner.address.isCompany) {
          return `${owner.address.companyName}`;
        }
        return `${owner.address.firstName} ${owner.address.lastName}`;
      }
      return this.$auth.user?.userName ?? '';
    },
    membershipNumber(): string | null | undefined {
      if (this.isMembershipDataLoading) {
        return '…';
      }
      if (this.hasMembership && this.membership) {
        return this.membership.membershipNumber;
      }
      return this.$t('app.noMembership');
    },
    hasMembership(): boolean {
      return this.$service.api.memberships.getIsMember();
    },
  },
  beforeMount() {
    this.isSidedrawerVisible = this.$localStorage.get<boolean>('isSidedrawerVisible') ?? true;
  },
  async mounted() {
    await this.populateMembershipData();
    // PWA
    const addBtn = document.querySelector('.pwa-add-button') as HTMLElement;
    if (addBtn && Vue.$store.values.showPwaInstallPromptFunction) {
      addBtn.addEventListener('click', Vue.$store.values.showPwaInstallPromptFunction);
    }
  },
  methods: {
    navigationClicked() {
      if (this.$media.isMobile) {
        this.toggleSideDrawerVisible(false);
      }
    },
    async doLogoff() {
      try {
        await this.$auth.logout();
        this.$router.push('/_exterior/login');
      } catch (error) {
        console.error('Unable to log out an error occurred');
        console.error(error);
      }
    },
    async populateMembershipData() {
      this.isMembershipDataLoading = true;
      this.membership = await this.$service.api.memberships.getMyMembership();
      this.isMembershipDataLoading = false;
    },
    toggleSideDrawerVisible(isVisible?: boolean) {
      const newVisibleState = isVisible ?? !this.isSidedrawerVisible;
      this.isSidedrawerVisible = newVisibleState;
      this.$localStorage.set('isSidedrawerVisible', this.isSidedrawerVisible);
    },
  },
});
