// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import DynamicFormChoiceResourceDTO from '../../dto/resource/DynamicFormChoiceResourceDTO';

export default abstract class DynamicFormChoiceResourceModel extends BaseModel<DynamicFormChoiceResourceDTO> {
  /**
  */
  get description() { return this.dto.description; }
  set description(value) { this.dto.description = value; }
  /**
  */
  get value() { return this.dto.value; }
  set value(value) { this.dto.value = value; }
  /**
  * @type {int32}
  */
  get orderSequence() { return this.dto.orderSequence; }
  set orderSequence(value) { this.dto.orderSequence = value; }
  /**
  */
  get isChosenByDefault() { return this.dto.isChosenByDefault; }
  set isChosenByDefault(value) { this.dto.isChosenByDefault = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    this.dto.description = queries.description;
    this.dto.value = queries.value;
    value = Number.parseFloat(queries.orderSequence);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.orderSequence = value;
    value = queries.isChosenByDefault === 'true' ? true : queries.isChosenByDefault;
    value = queries.isChosenByDefault === 'false' ? false : undefined;
    this.dto.isChosenByDefault = value;
  }
}
