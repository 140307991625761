// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import GastronomyResourceDTO from '../../dto/resource/GastronomyResourceDTO';

export default abstract class GastronomyResourceModel extends BaseModel<GastronomyResourceDTO> {
  /**
  * @type {uuid}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {uuid}
  */
  get businessTypeId() { return this.dto.businessTypeId; }
  set businessTypeId(value) { this.dto.businessTypeId = value; }
  /**
  */
  get businessTypeName() { return this.dto.businessTypeName; }
  set businessTypeName(value) { this.dto.businessTypeName = value; }
  /**
  * @type {int32}
  */
  get placesHall() { return this.dto.placesHall; }
  set placesHall(value) { this.dto.placesHall = value; }
  /**
  * @type {int32}
  */
  get placesTerrace() { return this.dto.placesTerrace; }
  set placesTerrace(value) { this.dto.placesTerrace = value; }
  /**
  * @type {int32}
  */
  get placesInHouse() { return this.dto.placesInHouse; }
  set placesInHouse(value) { this.dto.placesInHouse = value; }
  /**
  */
  get isWheelchairAccessible() { return this.dto.isWheelchairAccessible; }
  set isWheelchairAccessible(value) { this.dto.isWheelchairAccessible = value; }
  /**
  */
  get isRestRoomHandicappedAccessible() { return this.dto.isRestRoomHandicappedAccessible; }
  set isRestRoomHandicappedAccessible(value) { this.dto.isRestRoomHandicappedAccessible = value; }
  /**
  */
  get hasSmokingRoom() { return this.dto.hasSmokingRoom; }
  set hasSmokingRoom(value) { this.dto.hasSmokingRoom = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    this.dto.id = queries.id;
    this.dto.businessTypeId = queries.businessTypeId;
    this.dto.businessTypeName = queries.businessTypeName;
    value = Number.parseFloat(queries.placesHall);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.placesHall = value;
    value = Number.parseFloat(queries.placesTerrace);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.placesTerrace = value;
    value = Number.parseFloat(queries.placesInHouse);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.placesInHouse = value;
    value = queries.isWheelchairAccessible === 'true' ? true : queries.isWheelchairAccessible;
    value = queries.isWheelchairAccessible === 'false' ? false : undefined;
    this.dto.isWheelchairAccessible = value;
    value = queries.isRestRoomHandicappedAccessible === 'true' ? true : queries.isRestRoomHandicappedAccessible;
    value = queries.isRestRoomHandicappedAccessible === 'false' ? false : undefined;
    this.dto.isRestRoomHandicappedAccessible = value;
    value = queries.hasSmokingRoom === 'true' ? true : queries.hasSmokingRoom;
    value = queries.hasSmokingRoom === 'false' ? false : undefined;
    this.dto.hasSmokingRoom = value;
  }
}
