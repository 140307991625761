import Vue from 'vue';
import CreateSubscriptionContractRequestModel from '../../generated/types/model/resource/CreateSubscriptionContractRequestModel';
import RequestSummaryResourceViewModel from './RequestSummaryResourceViewModel';
import utils from '../utils';

export default class CreateSubscriptionContractRequestViewModel extends CreateSubscriptionContractRequestModel {
  protected transformToDTO() {
    this.dto.summary = this.summary.getDTO();
  }
  protected transformFromDTO() {}

  get summary(): RequestSummaryResourceViewModel {
    return new RequestSummaryResourceViewModel().fromDTO({
      sections: [
        {
          title: Vue.$t('page.subscription.label.stepTitles.1'),
          items: [
            {
              label: Vue.$t('form.address.language'),
              displayValue: this.languageIso2?.toUpperCase(),
            },
            ...this.orderBasket.items.map((item : any) => ({
              label: item.caption!,
              displayValue: Vue.$format.currency(item.grossPrice!, item.currency as any ?? 'CHF'),
            })),
          ],
        },
        {
          title: Vue.$t('page.subscription.label.stepTitles.2'),
          items: [
            ...this.billingContact.address.getSummaryItems({ hideLanguageIso2: true }),
          ],
        },
        {
          title: Vue.$t('page.subscription.label.stepTitles.3'),
          items: [
            {
              label: Vue.$t('page.subscription.label.hasDifferentDeliveryAddress'),
              displayValue: this.deliveryContact.isDifferentFromOwner ? Vue.$t('app.yes') : Vue.$t('app.no'),
            },
            ...utils.insertIf(this.deliveryContact.isDifferentFromOwner!,
              this.deliveryContact.address.getSummaryItems({ hideLanguageIso2: true })),
          ],
        },
      ].map((section) => ({
        ...section,
        items: section?.items?.filter((item: any) => item.label !== undefined && item.displayValue !== undefined && item.displayValue !== null) ?? [],
      })),
    });
  }
}
