
















import Vue from 'vue';
import GetLocationsRequestViewModel from '@/src/services/viewModel/request/Locations/GetLocationsRequestViewModel';
import LocationCitySearchResourceViewModel from '@/src/services/viewModel/resource/LocationCitySearchResourceViewModel';

export default Vue.extend({
  props: {
    value: { default: undefined, type: undefined },
    disabled: { default: false, type: Boolean },
    readonly: { default: false, type: Boolean },
    solo: { default: false, type: Boolean },
    placeholder: { default: undefined, type: String },
    label: { default: undefined, type: String },
  },
  data: () => ({
    selected: undefined,
    search: undefined,
    items: [] as LocationCitySearchResourceViewModel[],
  }),
  computed: {
    computedLabel(): string {
      return this.label ?? this.$t('form.address.plz');
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.performLookup(this.value as any);
        this.$set(this, 'selected', this.value);
      },
    },
    selected() {
      this.$emit('select', this.items.find((item) => { return item.postalCode === this.selected; }));
    },
  },
  methods: {
    async performLookup(search: string) {
      const query = new GetLocationsRequestViewModel().fromModel({
        'paging.CurrentPage': 1,
        'paging.PageSize': 10,
        postalCodeCriteria: search,
      });
      const list = await this.$service.api.locations.getLocations(query);
      this.items = list.items;
    },
    onSearch(value: string) {
      // TODO: p-lookup should do this itself, but it does not debounce properly
      this.$debounce(() => {
        this.performLookup(value);
      }, 300, this)();
    },
  },
});
