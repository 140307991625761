// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import AppInfoComponentResourceViewModel from '../../../../viewModel/resource/AppInfoComponentResourceViewModel';
import AppInfoResourceDTO from '../../dto/resource/AppInfoResourceDTO';

export default abstract class AppInfoResourceModel extends BaseModel<AppInfoResourceDTO> {
  /**
  */
  get title() { return this.dto.title; }
  set title(value) { this.dto.title = value; }
  /**
  */
  get version() { return this.dto.version; }
  set version(value) { this.dto.version = value; }
  /**
  */
  get healthEndpoint() { return this.dto.healthEndpoint; }
  set healthEndpoint(value) { this.dto.healthEndpoint = value; }
  /**
  */
  get environment() { return this.dto.environment; }
  set environment(value) { this.dto.environment = value; }
  /**
  */
  get components() { return this.getArrayModels(() => this.dto.components, AppInfoComponentResourceViewModel)!; }
  set components(value) { this.setArrayModels(() => this.dto.components, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    this.dto.title = queries.title;
    this.dto.version = queries.version;
    this.dto.healthEndpoint = queries.healthEndpoint;
    this.dto.environment = queries.environment;
  }
}
