// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ContactResourceViewModel from '../../../../viewModel/resource/ContactResourceViewModel';
import MemberBusinessAddressResourceViewModel from '../../../../viewModel/resource/MemberBusinessAddressResourceViewModel';
import CorrespondenceAddressResourceViewModel from '../../../../viewModel/resource/CorrespondenceAddressResourceViewModel';
import MembershipBusinessResourceViewModel from '../../../../viewModel/resource/MembershipBusinessResourceViewModel';
import BasketResourceViewModel from '../../../../viewModel/resource/BasketResourceViewModel';
import RequestSummaryResourceViewModel from '../../../../viewModel/resource/RequestSummaryResourceViewModel';
import CreateMembershipRequestDTO from '../../dto/resource/CreateMembershipRequestDTO';

export default abstract class CreateMembershipRequestModel extends BaseModel<CreateMembershipRequestDTO> {
  /**
  * @type {int64}
  */
  get userId() { return this.dto.userId; }
  set userId(value) { this.dto.userId = value; }
  /**
  * @type {uuid}
  */
  get businessUnitId() { return this.dto.businessUnitId; }
  set businessUnitId(value) { this.dto.businessUnitId = value; }
  /**
  * @type {uuid}
  */
  get membershipTemplateId() { return this.dto.membershipTemplateId; }
  set membershipTemplateId(value) { this.dto.membershipTemplateId = value; }
  /**
  * @type {int32}
  */
  get payrollCategoryId() { return this.dto.payrollCategoryId; }
  set payrollCategoryId(value) { this.dto.payrollCategoryId = value; }
  /**
  */
  get isMembershipReEntry() { return this.dto.isMembershipReEntry; }
  set isMembershipReEntry(value) { this.dto.isMembershipReEntry = value; }
  /**
  * @type {date-time}
  */
  get entryDate() { return this.dto.entryDate; }
  set entryDate(value) { this.dto.entryDate = value; }
  /**
  * @type {uuid}
  */
  get socialInsuranceId() { return this.dto.socialInsuranceId; }
  set socialInsuranceId(value) { this.dto.socialInsuranceId = value; }
  /**
  */
  get hasOperatingLicence() { return this.dto.hasOperatingLicence; }
  set hasOperatingLicence(value) { this.dto.hasOperatingLicence = value; }
  /**
  */
  get operatingLicenceOwner() { return this.dto.operatingLicenceOwner; }
  set operatingLicenceOwner(value) { this.dto.operatingLicenceOwner = value; }
  /**
  * @type {date-time}
  */
  get dateOperatingLicenceIssued() { return this.dto.dateOperatingLicenceIssued; }
  set dateOperatingLicenceIssued(value) { this.dto.dateOperatingLicenceIssued = value; }
  /**
  */
  get hasCertificateOfProficiency() { return this.dto.hasCertificateOfProficiency; }
  set hasCertificateOfProficiency(value) { this.dto.hasCertificateOfProficiency = value; }
  /**
  * @type {date-time}
  */
  get dateCertificateOfProficiencyIssued() { return this.dto.dateCertificateOfProficiencyIssued; }
  set dateCertificateOfProficiencyIssued(value) { this.dto.dateCertificateOfProficiencyIssued = value; }
  /**
  * @type {int64}
  */
  get certificateOfProficiencyCantonId() { return this.dto.certificateOfProficiencyCantonId; }
  set certificateOfProficiencyCantonId(value) { this.dto.certificateOfProficiencyCantonId = value; }
  /**
  */
  get certificateOfProficiencyCourse() { return this.dto.certificateOfProficiencyCourse; }
  set certificateOfProficiencyCourse(value) { this.dto.certificateOfProficiencyCourse = value; }
  /**
  */
  get isMemberContactReadonly() { return this.dto.isMemberContactReadonly; }
  set isMemberContactReadonly(value) { this.dto.isMemberContactReadonly = value; }
  /**
  */
  get memberContact() { return this.getObjectModel(() => this.dto.memberContact, ContactResourceViewModel)!; }
  set memberContact(value) { this.setObjectModel(() => this.dto.memberContact, value); }
  /**
  */
  get isMemberBusinessContactReadonly() { return this.dto.isMemberBusinessContactReadonly; }
  set isMemberBusinessContactReadonly(value) { this.dto.isMemberBusinessContactReadonly = value; }
  /**
  */
  get memberBusinessContact() { return this.getObjectModel(() => this.dto.memberBusinessContact, MemberBusinessAddressResourceViewModel)!; }
  set memberBusinessContact(value) { this.setObjectModel(() => this.dto.memberBusinessContact, value); }
  /**
  */
  get isMemberCorrespondeceContactReadonly() { return this.dto.isMemberCorrespondeceContactReadonly; }
  set isMemberCorrespondeceContactReadonly(value) { this.dto.isMemberCorrespondeceContactReadonly = value; }
  /**
  */
  get memberCorrespondenceContact() { return this.getObjectModel(() => this.dto.memberCorrespondenceContact, CorrespondenceAddressResourceViewModel)!; }
  set memberCorrespondenceContact(value) { this.setObjectModel(() => this.dto.memberCorrespondenceContact, value); }
  /**
  */
  get isMembershipBusinessReadonly() { return this.dto.isMembershipBusinessReadonly; }
  set isMembershipBusinessReadonly(value) { this.dto.isMembershipBusinessReadonly = value; }
  /**
  */
  get membershipBusiness() { return this.getObjectModel(() => this.dto.membershipBusiness, MembershipBusinessResourceViewModel)!; }
  set membershipBusiness(value) { this.setObjectModel(() => this.dto.membershipBusiness, value); }
  /**
  */
  get orderBasket() { return this.getObjectModel(() => this.dto.orderBasket, BasketResourceViewModel)!; }
  set orderBasket(value) { this.setObjectModel(() => this.dto.orderBasket, value); }
  /**
  */
  get summary() { return this.getObjectModel(() => this.dto.summary, RequestSummaryResourceViewModel)!; }
  set summary(value) { this.setObjectModel(() => this.dto.summary, value); }
  /**
  */
  get acceptIsRelatedToGastro() { return this.dto.acceptIsRelatedToGastro; }
  set acceptIsRelatedToGastro(value) { this.dto.acceptIsRelatedToGastro = value; }
  /**
  */
  get acceptGsoc() { return this.dto.acceptGsoc; }
  set acceptGsoc(value) { this.dto.acceptGsoc = value; }
  /**
  */
  get acceptAgb() { return this.dto.acceptAgb; }
  set acceptAgb(value) { this.dto.acceptAgb = value; }
  /**
  */
  get acceptFilledOutTruthful() { return this.dto.acceptFilledOutTruthful; }
  set acceptFilledOutTruthful(value) { this.dto.acceptFilledOutTruthful = value; }
  /**
  */
  get acceptGsStatutes() { return this.dto.acceptGsStatutes; }
  set acceptGsStatutes(value) { this.dto.acceptGsStatutes = value; }
  /**
  */
  get newsletter() { return this.dto.newsletter; }
  set newsletter(value) { this.dto.newsletter = value; }
  /**
  */
  get acceptGdpr() { return this.dto.acceptGdpr; }
  set acceptGdpr(value) { this.dto.acceptGdpr = value; }
  /**
  */
  get acceptContactForClassification() { return this.dto.acceptContactForClassification; }
  set acceptContactForClassification(value) { this.dto.acceptContactForClassification = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    value = Number.parseFloat(queries.userId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.userId = value;
    this.dto.businessUnitId = queries.businessUnitId;
    this.dto.membershipTemplateId = queries.membershipTemplateId;
    value = Number.parseFloat(queries.payrollCategoryId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.payrollCategoryId = value;
    value = queries.isMembershipReEntry === 'true' ? true : queries.isMembershipReEntry;
    value = queries.isMembershipReEntry === 'false' ? false : undefined;
    this.dto.isMembershipReEntry = value;
    this.dto.entryDate = queries.entryDate;
    this.dto.socialInsuranceId = queries.socialInsuranceId;
    value = queries.hasOperatingLicence === 'true' ? true : queries.hasOperatingLicence;
    value = queries.hasOperatingLicence === 'false' ? false : undefined;
    this.dto.hasOperatingLicence = value;
    this.dto.operatingLicenceOwner = queries.operatingLicenceOwner;
    this.dto.dateOperatingLicenceIssued = queries.dateOperatingLicenceIssued;
    value = queries.hasCertificateOfProficiency === 'true' ? true : queries.hasCertificateOfProficiency;
    value = queries.hasCertificateOfProficiency === 'false' ? false : undefined;
    this.dto.hasCertificateOfProficiency = value;
    this.dto.dateCertificateOfProficiencyIssued = queries.dateCertificateOfProficiencyIssued;
    value = Number.parseFloat(queries.certificateOfProficiencyCantonId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.certificateOfProficiencyCantonId = value;
    this.dto.certificateOfProficiencyCourse = queries.certificateOfProficiencyCourse;
    value = queries.isMemberContactReadonly === 'true' ? true : queries.isMemberContactReadonly;
    value = queries.isMemberContactReadonly === 'false' ? false : undefined;
    this.dto.isMemberContactReadonly = value;
    value = queries.isMemberBusinessContactReadonly === 'true' ? true : queries.isMemberBusinessContactReadonly;
    value = queries.isMemberBusinessContactReadonly === 'false' ? false : undefined;
    this.dto.isMemberBusinessContactReadonly = value;
    value = queries.isMemberCorrespondeceContactReadonly === 'true' ? true : queries.isMemberCorrespondeceContactReadonly;
    value = queries.isMemberCorrespondeceContactReadonly === 'false' ? false : undefined;
    this.dto.isMemberCorrespondeceContactReadonly = value;
    value = queries.isMembershipBusinessReadonly === 'true' ? true : queries.isMembershipBusinessReadonly;
    value = queries.isMembershipBusinessReadonly === 'false' ? false : undefined;
    this.dto.isMembershipBusinessReadonly = value;
    value = queries.acceptIsRelatedToGastro === 'true' ? true : queries.acceptIsRelatedToGastro;
    value = queries.acceptIsRelatedToGastro === 'false' ? false : undefined;
    this.dto.acceptIsRelatedToGastro = value;
    value = queries.acceptGsoc === 'true' ? true : queries.acceptGsoc;
    value = queries.acceptGsoc === 'false' ? false : undefined;
    this.dto.acceptGsoc = value;
    value = queries.acceptAgb === 'true' ? true : queries.acceptAgb;
    value = queries.acceptAgb === 'false' ? false : undefined;
    this.dto.acceptAgb = value;
    value = queries.acceptFilledOutTruthful === 'true' ? true : queries.acceptFilledOutTruthful;
    value = queries.acceptFilledOutTruthful === 'false' ? false : undefined;
    this.dto.acceptFilledOutTruthful = value;
    value = queries.acceptGsStatutes === 'true' ? true : queries.acceptGsStatutes;
    value = queries.acceptGsStatutes === 'false' ? false : undefined;
    this.dto.acceptGsStatutes = value;
    value = queries.newsletter === 'true' ? true : queries.newsletter;
    value = queries.newsletter === 'false' ? false : undefined;
    this.dto.newsletter = value;
    value = queries.acceptGdpr === 'true' ? true : queries.acceptGdpr;
    value = queries.acceptGdpr === 'false' ? false : undefined;
    this.dto.acceptGdpr = value;
    value = queries.acceptContactForClassification === 'true' ? true : queries.acceptContactForClassification;
    value = queries.acceptContactForClassification === 'false' ? false : undefined;
    this.dto.acceptContactForClassification = value;
  }
}
