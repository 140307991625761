// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import AccommodationResourceDTO from '../../dto/resource/AccommodationResourceDTO';

export default abstract class AccommodationResourceModel extends BaseModel<AccommodationResourceDTO> {
  /**
  */
  get isAccommodation() { return this.dto.isAccommodation; }
  set isAccommodation(value) { this.dto.isAccommodation = value; }
  /**
  * @type {uuid}
  */
  get accommodationTypeId() { return this.dto.accommodationTypeId; }
  set accommodationTypeId(value) { this.dto.accommodationTypeId = value; }
  /**
  */
  get accommodationTypeName() { return this.dto.accommodationTypeName; }
  set accommodationTypeName(value) { this.dto.accommodationTypeName = value; }
  /**
  */
  get isClassified() { return this.dto.isClassified; }
  set isClassified(value) { this.dto.isClassified = value; }
  /**
  * @type {int32}
  */
  get classificationStarsCount() { return this.dto.classificationStarsCount; }
  set classificationStarsCount(value) { this.dto.classificationStarsCount = value; }
  /**
  * @type {int32}
  */
  get classificationRoomCount() { return this.dto.classificationRoomCount; }
  set classificationRoomCount(value) { this.dto.classificationRoomCount = value; }
  /**
  * @type {int32}
  */
  get classificationBedCount() { return this.dto.classificationBedCount; }
  set classificationBedCount(value) { this.dto.classificationBedCount = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    value = queries.isAccommodation === 'true' ? true : queries.isAccommodation;
    value = queries.isAccommodation === 'false' ? false : undefined;
    this.dto.isAccommodation = value;
    this.dto.accommodationTypeId = queries.accommodationTypeId;
    this.dto.accommodationTypeName = queries.accommodationTypeName;
    value = queries.isClassified === 'true' ? true : queries.isClassified;
    value = queries.isClassified === 'false' ? false : undefined;
    this.dto.isClassified = value;
    value = Number.parseFloat(queries.classificationStarsCount);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.classificationStarsCount = value;
    value = Number.parseFloat(queries.classificationRoomCount);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.classificationRoomCount = value;
    value = Number.parseFloat(queries.classificationBedCount);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.classificationBedCount = value;
  }
}
