// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import DocumentResourceDTO from '../../dto/resource/DocumentResourceDTO';

export default abstract class DocumentResourceModel extends BaseModel<DocumentResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }
  /**
  */
  get creatorName() { return this.dto.creatorName; }
  set creatorName(value) { this.dto.creatorName = value; }
  /**
  * @type {date-time}
  */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }
  /**
  * @type {int64}
  */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }
  /**
  */
  get modifierName() { return this.dto.modifierName; }
  set modifierName(value) { this.dto.modifierName = value; }
  /**
  * @type {date-time}
  */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }
  /**
  */
  get fileName() { return this.dto.fileName; }
  set fileName(value) { this.dto.fileName = value; }
  /**
  */
  get filePath() { return this.dto.filePath; }
  set filePath(value) { this.dto.filePath = value; }
  /**
  */
  get fileExtension() { return this.dto.fileExtension; }
  set fileExtension(value) { this.dto.fileExtension = value; }
  /**
  * @type {int64}
  */
  get contentLength() { return this.dto.contentLength; }
  set contentLength(value) { this.dto.contentLength = value; }
  /**
  */
  get contentType() { return this.dto.contentType; }
  set contentType(value) { this.dto.contentType = value; }
  /**
  */
  get originalFileName() { return this.dto.originalFileName; }
  set originalFileName(value) { this.dto.originalFileName = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    value = Number.parseFloat(queries.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(queries.creatorId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.creatorId = value;
    this.dto.creatorName = queries.creatorName;
    this.dto.created = queries.created;
    value = Number.parseFloat(queries.modifierId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modifierId = value;
    this.dto.modifierName = queries.modifierName;
    this.dto.modified = queries.modified;
    this.dto.fileName = queries.fileName;
    this.dto.filePath = queries.filePath;
    this.dto.fileExtension = queries.fileExtension;
    value = Number.parseFloat(queries.contentLength);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.contentLength = value;
    this.dto.contentType = queries.contentType;
    this.dto.originalFileName = queries.originalFileName;
  }
}
