// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import Int32LookupRequestViewModel from '../../../../viewModel/resource/Int32LookupRequestViewModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import Int32LookupRequestListResultDTO from '../../dto/resource/Int32LookupRequestListResultDTO';

export default abstract class Int32LookupRequestListResultModel extends BaseModel<Int32LookupRequestListResultDTO> {
  /**
  */
  get items() { return this.getArrayModels(() => this.dto.items, Int32LookupRequestViewModel)!; }
  set items(value) { this.setArrayModels(() => this.dto.items, value); }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
  }
}
