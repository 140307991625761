// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import MembershipDocumentListResourceDTO from '../../dto/resource/MembershipDocumentListResourceDTO';

export default abstract class MembershipDocumentListResourceModel extends BaseModel<MembershipDocumentListResourceDTO> {
  /**
  * @type {uuid}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  */
  get subject() { return this.dto.subject; }
  set subject(value) { this.dto.subject = value; }
  /**
  */
  get documentType() { return this.dto.documentType; }
  set documentType(value) { this.dto.documentType = value; }
  /**
  */
  get fileExtension() { return this.dto.fileExtension; }
  set fileExtension(value) { this.dto.fileExtension = value; }
  /**
  */
  get downloadUrl() { return this.dto.downloadUrl; }
  set downloadUrl(value) { this.dto.downloadUrl = value; }
  /**
  * @type {date-time}
  */
  get createdOn() { return this.dto.createdOn; }
  set createdOn(value) { this.dto.createdOn = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    this.dto.id = queries.id;
    this.dto.subject = queries.subject;
    this.dto.documentType = queries.documentType;
    this.dto.fileExtension = queries.fileExtension;
    this.dto.downloadUrl = queries.downloadUrl;
    this.dto.createdOn = queries.createdOn;
  }
}
