import Vue from 'vue';
import SubscriptionContractResourceModel from '../../generated/types/model/resource/SubscriptionContractResourceModel';
import utils from '../utils';
import RequestSummaryItemResourceViewModel from './RequestSummaryItemResourceViewModel';
import RequestSummaryResourceViewModel from './RequestSummaryResourceViewModel';

export default class SubscriptionContractResourceViewModel extends SubscriptionContractResourceModel {
  protected transformToDTO() {}
  protected transformFromDTO() {}

  get billingSummary(): RequestSummaryResourceViewModel {
    return new RequestSummaryResourceViewModel().fromDTO({
      sections: [
        {
          items: [
            {
              ...utils.insertIf(!!this.billingContact.customerNumber, {
                label: Vue.$t('page.memberData.table.memberNumber'),
                displayValue: this.billingContact.customerNumber,
              }),
            },
            {
              ...utils.insertIf(!!this.billingContact.languageIso2, {
                label: Vue.$t('page.memberData.table.language'),
                displayValue: this.billingContact.languageIso2,
              }),
            },
            ...this.billingContact.address.getSummaryItems(),
          ],
        },
      ],
    });
  }

  get deliverySummary(): RequestSummaryResourceViewModel {
    if (this.deliveryContact.isEqualToDefaultAddress) {
      return new RequestSummaryResourceViewModel().fromDTO({
        sections: [
          {
            items: [
              { label: Vue.$t('page.subscription.label.isEqualToBillingAddress') },
            ],
          },
        ],
      });
    }
    return new RequestSummaryResourceViewModel().fromDTO({
      sections: [
        {
          items: [
            ...this.deliveryContact.address.getSummaryItems(),
          ],
        },
      ],
    });
  }
}
