








































































import Vue from 'vue';

export default Vue.extend({
  auth: true,
  data: () => ({
    date: Vue.$date.now().format('YYYY-MM-DD'),
    isMobile: false,
    profileMenuVisible: false,
    isSidedrawerVisible: true,
  }),
  computed: {
    user() {
      return this.$auth.user ?? {};
    },
    isHostAdmin() {
      let isAdmin = false;
      if (this.$auth.isLoggedIn) {
        isAdmin = this.$auth.isInRole('HostAdmin');
      }
      return isAdmin;
    },
    isPwaButtonVisible(): boolean {
      return !!Vue.$store.values.showPwaInstallPromptFunction;
    },
  },
  beforeMount() {
    if (!this.isHostAdmin) {
      this.$auth.redirectToLogin();
    }
    this.isSidedrawerVisible = this.$localStorage.get<boolean>('isSidedrawerVisible') ?? true;
  },
  async mounted() {
    this.onResize();
    // PWA
    const addBtn = document.querySelector('.pwa-add-button') as HTMLElement;
    if (addBtn && Vue.$store.values.showPwaInstallPromptFunction) {
      addBtn.addEventListener('click', Vue.$store.values.showPwaInstallPromptFunction);
    }
  },
  methods: {
    async doLogoff() {
      try {
        await this.$auth.logout();
        this.$router.push('/_exterior/login');
      } catch (error) {
        console.error('Unable to log out an error occurred');
        console.error(error);
      }
    },
    onResize() {
      this.isMobile = window.innerWidth <= 600;
    },
    toggleSideDrawerVisible() {
      this.isSidedrawerVisible = !this.isSidedrawerVisible;
      this.$localStorage.set('isSidedrawerVisible', this.isSidedrawerVisible);
    },
  },
});
