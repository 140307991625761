// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ApiPagingRequestDTO from '../../dto/resource/ApiPagingRequestDTO';

export default abstract class ApiPagingRequestModel extends BaseModel<ApiPagingRequestDTO> {
  /**
  * @type {int32}
  */
  get currentPage() { return this.dto.currentPage; }
  set currentPage(value) { this.dto.currentPage = value; }
  /**
  * @type {int32}
  */
  get pageSize() { return this.dto.pageSize; }
  set pageSize(value) { this.dto.pageSize = value; }
  /**
  */
  get includeCount() { return this.dto.includeCount; }
  set includeCount(value) { this.dto.includeCount = value; }
  /**
  */
  get noPaging() { return this.dto.noPaging; }
  set noPaging(value) { this.dto.noPaging = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    value = Number.parseFloat(queries.currentPage);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.currentPage = value;
    value = Number.parseFloat(queries.pageSize);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.pageSize = value;
    value = queries.includeCount === 'true' ? true : queries.includeCount;
    value = queries.includeCount === 'false' ? false : undefined;
    this.dto.includeCount = value;
    value = queries.noPaging === 'true' ? true : queries.noPaging;
    value = queries.noPaging === 'false' ? false : undefined;
    this.dto.noPaging = value;
  }
}
