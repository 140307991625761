// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetLocationsRequestViewModel from '../../viewModel/request/Locations/GetLocationsRequestViewModel';
import LookupCountriesRequestViewModel from '../../viewModel/request/Locations/LookupCountriesRequestViewModel';
import LookupStatesRequestViewModel from '../../viewModel/request/Locations/LookupStatesRequestViewModel';
import LocationCitySearchResourcePagingResultViewModel from '../../viewModel/resource/LocationCitySearchResourcePagingResultViewModel';
import Int64LookupResourcePagingResultViewModel from '../../viewModel/resource/Int64LookupResourcePagingResultViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getLocations(request: GetLocationsRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-locations'] ?? 'core/Locations';
    const response = await service.get<any>(endpointPath, {
      query: {
        postalCodeCriteria: requestDTO.postalCodeCriteria,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new LocationCitySearchResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async lookupCountries(request: LookupCountriesRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-countries'] ?? 'core/Locations/LookupCountries';
    const response = await service.get<any>(endpointPath, {
      query: {
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new Int64LookupResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async lookupStates(request: LookupStatesRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-states'] ?? 'core/Locations/LookupStates';
    const response = await service.get<any>(endpointPath, {
      query: {
        countryId: requestDTO.countryId,
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new Int64LookupResourcePagingResultViewModel().fromDTO(dto);
  },
});
