// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ContactAddressResourceViewModel from '../../../../viewModel/resource/ContactAddressResourceViewModel';
import CorrespondenceContactResourceDTO from '../../dto/resource/CorrespondenceContactResourceDTO';

export default abstract class CorrespondenceContactResourceModel extends BaseModel<CorrespondenceContactResourceDTO> {
  /**
  * @type {int32}
  */
  get customerNumber() { return this.dto.customerNumber; }
  set customerNumber(value) { this.dto.customerNumber = value; }
  /**
  * @type {uuid}
  */
  get contactId() { return this.dto.contactId; }
  set contactId(value) { this.dto.contactId = value; }
  /**
  * @type {uuid}
  */
  get parentContactId() { return this.dto.parentContactId; }
  set parentContactId(value) { this.dto.parentContactId = value; }
  /**
  */
  get languageIso2() { return this.dto.languageIso2; }
  set languageIso2(value) { this.dto.languageIso2 = value; }
  /**
  */
  get address() { return this.getObjectModel(() => this.dto.address, ContactAddressResourceViewModel)!; }
  set address(value) { this.setObjectModel(() => this.dto.address, value); }
  /**
  */
  get isEqualToDefaultAddress() { return this.dto.isEqualToDefaultAddress; }
  set isEqualToDefaultAddress(value) { this.dto.isEqualToDefaultAddress = value; }
  /**
  */
  get isTrusteeAddress() { return this.dto.isTrusteeAddress; }
  set isTrusteeAddress(value) { this.dto.isTrusteeAddress = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    value = Number.parseFloat(queries.customerNumber);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.customerNumber = value;
    this.dto.contactId = queries.contactId;
    this.dto.parentContactId = queries.parentContactId;
    this.dto.languageIso2 = queries.languageIso2;
    value = queries.isEqualToDefaultAddress === 'true' ? true : queries.isEqualToDefaultAddress;
    value = queries.isEqualToDefaultAddress === 'false' ? false : undefined;
    this.dto.isEqualToDefaultAddress = value;
    value = queries.isTrusteeAddress === 'true' ? true : queries.isTrusteeAddress;
    value = queries.isTrusteeAddress === 'false' ? false : undefined;
    this.dto.isTrusteeAddress = value;
  }
}
