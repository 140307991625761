// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import DynamicFormFieldResourceViewModel from '../../../../viewModel/resource/DynamicFormFieldResourceViewModel';
import DynamicFormChoiceResourceViewModel from '../../../../viewModel/resource/DynamicFormChoiceResourceViewModel';
import DynamicFormFieldResourceDTO from '../../dto/resource/DynamicFormFieldResourceDTO';

export default abstract class DynamicFormFieldResourceModel extends BaseModel<DynamicFormFieldResourceDTO> {
  /**
  * @type {uuid}
  */
  get dynamicFormFieldDefinitionId() { return this.dto.dynamicFormFieldDefinitionId; }
  set dynamicFormFieldDefinitionId(value) { this.dto.dynamicFormFieldDefinitionId = value; }
  /**
  * @type {int32}
  */
  get orderSequence() { return this.dto.orderSequence; }
  set orderSequence(value) { this.dto.orderSequence = value; }
  /**
  */
  get description() { return this.dto.description; }
  set description(value) { this.dto.description = value; }
  /**
  */
  get isMandatory() { return this.dto.isMandatory; }
  set isMandatory(value) { this.dto.isMandatory = value; }
  /**
  */
  get enableMultiline() { return this.dto.enableMultiline; }
  set enableMultiline(value) { this.dto.enableMultiline = value; }
  /**
  * @type {int32}
  */
  get type() { return this.dto.type; }
  set type(value) { this.dto.type = value; }
  /**
  */
  get groupFields() { return this.getArrayModels(() => this.dto.groupFields, DynamicFormFieldResourceViewModel)!; }
  set groupFields(value) { this.setArrayModels(() => this.dto.groupFields, value); }
  /**
  */
  get choices() { return this.getArrayModels(() => this.dto.choices, DynamicFormChoiceResourceViewModel)!; }
  set choices(value) { this.setArrayModels(() => this.dto.choices, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    this.dto.dynamicFormFieldDefinitionId = queries.dynamicFormFieldDefinitionId;
    value = Number.parseFloat(queries.orderSequence);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.orderSequence = value;
    this.dto.description = queries.description;
    value = queries.isMandatory === 'true' ? true : queries.isMandatory;
    value = queries.isMandatory === 'false' ? false : undefined;
    this.dto.isMandatory = value;
    value = queries.enableMultiline === 'true' ? true : queries.enableMultiline;
    value = queries.enableMultiline === 'false' ? false : undefined;
    this.dto.enableMultiline = value;
    value = Number.parseFloat(queries.type);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.type = value;
  }
}
