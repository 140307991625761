// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import DynamicFormFieldResourceViewModel from '../../../../viewModel/resource/DynamicFormFieldResourceViewModel';
import DynamicFormResourceDTO from '../../dto/resource/DynamicFormResourceDTO';

export default abstract class DynamicFormResourceModel extends BaseModel<DynamicFormResourceDTO> {
  /**
  * @type {uuid}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  */
  get description() { return this.dto.description; }
  set description(value) { this.dto.description = value; }
  /**
  */
  get formFields() { return this.getArrayModels(() => this.dto.formFields, DynamicFormFieldResourceViewModel)!; }
  set formFields(value) { this.setArrayModels(() => this.dto.formFields, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    this.dto.id = queries.id;
    this.dto.description = queries.description;
  }
}
