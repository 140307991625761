import Vue from 'vue';
import core from '@glittr/frontend-core';
import router from './router';
import localization from './localization';
import services from './services';
import rsLanguageSelection from './components/rs-language-selection.vue';
import rsWizard from './components/processes/rs-wizard.vue';
import rsWizardStep from './components/processes/rs-wizard-step.vue';
import rsToggleValue from './components/rs-toggle-value.vue';
import rsMembershipCheckWrapper from './components/rs-membership-check-wrapper.vue';
import rsFormAddress from './components/forms/rs-form-address.vue';
import rsRaiseNowTamaro from './components/rs-raise-now-tamaro.vue';
import rsLocationField from './components/forms/rs-location-field.vue';
import rsCountryLookup from './components/forms/rs-country-lookup.vue';
import rsStateLookup from './components/forms/rs-state-lookup.vue';
import rsSubscriptionInfo from './components/subscription/rs-subscription-info.vue';
import rsAddressDisplay from './components/rs-address-display.vue';
import rsSummaryDisplay from './components/rs-summary-display.vue';
import rsIssu from './components/rs-issu.vue';
import omDynamicForm from './components/om-dynamic-form.vue';
import registerServiceWorker from './register-service-worker';

Vue.config.productionTip = false;

// Core entry point
Vue.use(core, {
  // appId: 'mount-id',
  translations: localization.translations,
  router,
  Vue,
});

Vue.$config.promise.then(() => {
  registerServiceWorker();
});

// APP____________________________________________________________
Vue.component('RsLanguageSelection', rsLanguageSelection);
Vue.component('RsMembershipCheckWrapper', rsMembershipCheckWrapper);
Vue.component('RsWizard', rsWizard);
Vue.component('RsToggleValue', rsToggleValue);
Vue.component('RsFormAddress', rsFormAddress);
Vue.component('RsRaiseNowTamaro', rsRaiseNowTamaro);
Vue.component('RsWizardStep', rsWizardStep);
Vue.component('RsLocationField', rsLocationField);
Vue.component('RsCountryLookup', rsCountryLookup);
Vue.component('RsStateLookup', rsStateLookup);
Vue.component('RsSubscriptionInfo', rsSubscriptionInfo);
Vue.component('OmDynamicForm', omDynamicForm);
Vue.component('RsAddressDisplay', rsAddressDisplay);
Vue.component('RsSummaryDisplay', rsSummaryDisplay);
Vue.component('RsIssu', rsIssu);

Vue.use(services);

// STYLES
require('./assets/styles/main.scss');

// PLUGINS
// Vue.use(yourPlugin);

// GUARDS
// Vue.$guardWrapper.push(yourGuard);

// LAYOUTS
const reqLayouts = require.context('./views/layouts', true);
reqLayouts.keys().forEach((componentPath) => {
  let componentName = componentPath.substr(componentPath.lastIndexOf('/') + 1, componentPath.length - 1);
  componentName = componentName.replace('.vue', '');
  const component = reqLayouts(componentPath).default;
  Vue.component(componentName, component);
});

// _______________________________________________________________

declare module 'vue/types/options' {
  interface ComponentOptions<V extends Vue> {
    /** Does the user need to be logged in to view this page? */
    hasBackButton?: boolean;
  }
}

declare module '@core/plugins/config/config' {
  interface ConfigType {
    /** Shows the debug panel */
    'raisenow-tamaro-debug': boolean,
    /** Testmode enables test payments, if set to false real payments are performed! */
    'raisenow-tamaro-testmode': boolean,
    /** Fixed amount to pay with, is either a number, or false to disable fixed amount */
    'raisenow-tamaro-fixed-amount': boolean | number,
    'gs-business-legal-form-einzelunternehmen-lookup-id': string,
    /** Dynamic Form ID's */
    'dynamicForm-kitchenSinkId': string,
    'dynamicForm-gastroProfessionalTerminate': string,
    'dynamicForm-gastroJournalTerminate': string,
    'dynamicForm-gastroJournalPause': string,
    'dynamicForm-gastroJournalInterrupt': string,
    'dynamicForm-gastroJournalForward': string,
    'dynamicForm-memberChangeAddress': string,
    'dynamicForm-supportFeedback': string,
  }
}

require('./store');
