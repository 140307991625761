








































import Vue from 'vue';
import SubscriptionContractResourceViewModel from '@/src/services/viewModel/resource/SubscriptionContractResourceViewModel';

export default Vue.extend({
  props: {
    serviceFunction: { default: () => (() => Promise.resolve()), type: Function },
    showDeliveryAddress: { default: false, type: Boolean },
  },
  data: () => ({
    isLoading: false,
    errorResponse: undefined,
    subscriptionData: undefined as SubscriptionContractResourceViewModel | undefined,
    membershipId: undefined as any,
  }),
  async beforeMount() {
    this.membershipId = await this.$service.api.memberships.getMyMembershipId();
    this.populateData();
  },
  methods: {
    async populateData() {
      this.isLoading = true;
      try {
        this.subscriptionData = await this.serviceFunction();
        this.$emit('input', this.subscriptionData);
        // response = await this.$service.api.subscriptions.getMyGastroProfessionalSubscriptions();
      } catch (error: any) {
        this.errorResponse = error;
      }
      this.isLoading = false;
    },
  },
});
