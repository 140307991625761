











import Vue from 'vue';
import BasketResourceViewModel from '../services/viewModel/resource/BasketResourceViewModel';
import OnlinePaymentResourceViewModel from '../services/viewModel/resource/OnlinePaymentResourceViewModel';

export type TamaroPaymentViewType = 'payment_result' |
  'subscription_cancel' |
  'subscription_cancel_result' |
  'subscription_payment_method_update' |
  'subscription_payment_method_update_result' |
  'subscription_customer_update' |
  'subscription_customer_update_result';

export type TamaroPaymentMethodType = 'cc' // "Kreditkarte"
  | 'amx' // "American Express"
  | 'cym' // "Cymba"
  | 'din' // "Diners Club"
  | 'dd' // "Direct Debit"
  | 'dis' // "Discover"
  | 'ebs' // "EBS"
  | 'elv' // "ELV"
  | 'eps' // "EPS"
  | 'jcb' // "JCB"
  | 'mae' // "Maestro"
  | 'eca' // "MasterCard"
  | 'mpw' // "MasterPass Wallet"
  | 'es' // "Einzahlungsschein"
  | 'ezs' // "Einzahlungsschein"
  | 'pp' // "PayPal"
  | 'pef' // "PostFinance E-Finance"
  | 'pfc' // "PostFinance Card"
  | 'pfc_pef' // "PostFinance"
  | 'sod' // "SEPA Lastschrift"
  | 'sms' // "SMS"
  | 'dib' // "Sofort"
  | 'stp' // "Stripe"
  | 'twi' // "TWINT"
  | 'vis' // "Visa"
  | 'mpos'; // "RaiseNow Terminal"

export function getPaymentView(): TamaroPaymentViewType {
  return Vue.$router.currentRoute.query['rnw-view'] as TamaroPaymentViewType;
}

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    // Tamaro Widget
    rnw?: {
      tamaro?: {
        events: any,
        instance: any,
        isLoaded: boolean,
        cssVarsOverrider: any,
        // eslint-disable-next-line no-unused-vars
        runWidget(...args: any[]): void
        // eslint-disable-next-line no-unused-vars
        destroy(...args: any[]): void
      }
    }
  }
}

export default Vue.extend({
  props: {
    salutation: { type: String, default: null },
    firstname: { type: String, default: null },
    lastname: { type: String, default: null },
    email: { type: String, default: null },
    birthdate: { type: String, default: null },
    street: { type: String, default: null },
    streetNumber: { type: String, default: null },
    street2: { type: String, default: null },
    zipCode: { type: String, default: null },
    city: { type: String, default: null },
    companyName: { type: String, default: null },
    amount: { type: [Number, String], default: null },
    purpose: { type: String, default: null },
    currency: { type: String, default: 'chf' },
  },
  data: () => ({
    isScriptLoaded: false,
    isWidgetLoaded: false,
    showError: false,
    widgetLoadingTimeout: 30000, // ms
    widgetIsLoadedPollingInterval: 200, // ms
  }),
  computed: {
    selectedLanguage(): string {
      return this.$translation.get();
    },
    paymentView(): TamaroPaymentViewType {
      return getPaymentView();
    },
    paymentId(): string | undefined {
      return this.$router.currentRoute.query.epp_transaction_id as string | undefined;
    },
  },
  watch: {
    selectedLanguage() {
      this.loadTamaro();
    },
  },
  mounted() {
    const timeoutHandle = setTimeout(() => {
      this.showError = true;
      this.isScriptLoaded = true;
    }, this.widgetLoadingTimeout);
    const isLoadedPollerHandle = setInterval(() => {
      if (window.rnw) {
        this.isScriptLoaded = true;
        // In case it manages to load even after timeout, clear the error message
        this.showError = false;
        this.loadTamaro();
        clearTimeout(timeoutHandle);
        clearInterval(isLoadedPollerHandle);
      }
    }, this.widgetIsLoadedPollingInterval);
  },
  beforeDestroy() {
    window.rnw?.tamaro?.destroy();
  },
  methods: {
    mapPaymentMethod(methodString: TamaroPaymentMethodType) {
      switch (methodString) {
      case 'cc':
      case 'vis':
      case 'eca':
      case 'mae':
        return 2;
      case 'pfc':
      case 'pfc_pef':
        return 3;
      case 'twi':
      case 'pp':
        return 6;
      case 'pef':
        return 7;
      case 'dd':
        return 8;
      default:
        return 1; // Defaults to invoice
      }
    },
    mapPaymentType(methodString: TamaroPaymentMethodType) {
      switch (methodString) {
      case 'mpos':
        return 11;
      case 'sms':
        return 9;
      case 'amx':
        return 9;
      case 'pfc':
        return 5;
      case 'pfc_pef':
      case 'pef':
        return 6;
      case 'twi':
        return 7;
      case 'dd':
        return 8;
      case 'pp':
        return 8;
      case 'cc':
      case 'vis':
        return 3;
      case 'mae':
        return 10;
      case 'eca':
        return 2;
      default:
        console.error(`Unable to determine paymentTypeId, received the following payment type: ${methodString}`);
        return 0; // Unknown
      }
    },
    onPaymentDataEnd(event: any) {
      const { api } = event.data;
      const { transactionInfo } = api;
      if (transactionInfo.epayment_status === 'success') {
        if (this.paymentId) {
          const tamaro = window.rnw?.tamaro?.instance;
          const { data } = tamaro.paymentForm;
          const onlinePaymentInfo = new BasketResourceViewModel().fromModel({
            grossTotal: this.amount as number,
            currency: this.currency,
            paymentMethod: this.mapPaymentMethod(data.payment_method),
            onlinePayment: new OnlinePaymentResourceViewModel().fromModel({
              amount: data.amount,
              currency: data.currency,
              transactionId: this.paymentId,
              paymentDate: this.$date.now().format('YYYY-MM-DD'),
              paymentType: this.mapPaymentType(data.payment_method),
            }),
          });
          this.$emit('payment-success', onlinePaymentInfo);
        }
      } else if (transactionInfo.epayment_status === 'error') {
        this.$emit('payment-error');
      } else if (transactionInfo.epayment_status === 'cancel') {
        this.$emit('payment-cancel');
      }
    },
    onBeforeRender() {
      const tamaroConfig = window.rnw?.tamaro?.instance?.paymentForm?.getPaymentConfig();
      if (!tamaroConfig) {
        console.error('[Tamaro] Unable to load tamaro-widget properly, can not read version information');
        return;
      }
      const tamaroVersion = tamaroConfig.stored_rnw_product_version;
      console.log(`[Tamaro] Version: ${tamaroVersion} loaded`);
      this.$emit('loaded', window.rnw);
    },
    submitForm() {
      const tamaro = window.rnw?.tamaro?.instance;
      if (tamaro) {
        tamaro.paymentForm.validateAndSend();
      }
    },
    async loadTamaro() {
      if (!this.isScriptLoaded) {
        return;
      }
      const tamaro = window.rnw?.tamaro;
      if (tamaro?.events.beforeRender.handlers.length === 0) {
        tamaro?.events.beforeRender.subscribe(this.onBeforeRender);
        tamaro?.events.fetchPaymentDataEnd.subscribe(this.onPaymentDataEnd);
      }

      const fixedAmount = this.$config.values['raisenow-tamaro-fixed-amount'];
      const amount = fixedAmount || this.amount;
      const isDebug = this.$version.buildConfiguration === 'development';
      const isProduction = this.$version.buildConfiguration === 'production';
      await tamaro?.runWidget('.raise-now-widget-container', {
        language: this.selectedLanguage,
        testMode: Vue.$config.values['raisenow-tamaro-testmode'] ?? !isProduction,
        // Enable this for development (widget will print to console)
        debug: Vue.$config.values['raisenow-tamaro-debug'] ?? isDebug,
        paymentFormPrefill: {
          purpose: this.purpose,
          amount,
          currency: this.currency,
          card_holder_name: `${this.lastname} ${this.firstname}`,
          stored_customer_company: this.companyName,
          stored_customer_salutation: this.salutation,
          stored_customer_firstname: this.firstname,
          stored_customer_lastname: this.lastname,
          stored_customer_email: this.email,
          stored_customer_birthdate: this.birthdate,
          stored_customer_street: this.street,
          stored_customer_street_number: this.streetNumber,
          stored_customer_street2: this.street2,
          stored_customer_zip_code: this.zipCode,
          stored_customer_city: this.city,
          stored_customer_country: 'Schweiz',
        },
        translations: {
          de: {
            purposes: {
              p1: Vue.$t('page.createMembership.page.title'),
              p2: Vue.$t('page.newGastroJournalSubscription.page.title'),
              p3: Vue.$t('page.newGastroProfessionalSubscription.page.title'),
              p4: 'Spendenzweck 4',
            },
          },
          fr: {
            purposes: {
              p1: Vue.$t('page.createMembership.page.title'),
              p2: Vue.$t('page.newGastroJournalSubscription.page.title'),
              p3: Vue.$t('page.newGastroProfessionalSubscription.page.title'),
              p4: 'Purpose 4',
            },
          },
          it: {
            purposes: {
              p1: Vue.$t('page.createMembership.page.title'),
              p2: Vue.$t('page.newGastroJournalSubscription.page.title'),
              p3: Vue.$t('page.newGastroProfessionalSubscription.page.title'),
              p4: 'Purpose 4',
            },
          },
          en: {
            purposes: {
              p1: Vue.$t('page.createMembership.page.title'),
              p2: Vue.$t('page.newGastroJournalSubscription.page.title'),
              p3: Vue.$t('page.newGastroProfessionalSubscription.page.title'),
              p4: 'Purpose 4',
            },
          },
        },
      });
      this.isWidgetLoaded = true;
    },
  },
});
