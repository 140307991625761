// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetInitialSubscriptionRequestRequestViewModel from '../../viewModel/request/Subscriptions/GetInitialSubscriptionRequestRequestViewModel';
import SubscriptionTypeResourceViewModel from '../../viewModel/resource/SubscriptionTypeResourceViewModel';
import SubscriptionArticleResourceListResultViewModel from '../../viewModel/resource/SubscriptionArticleResourceListResultViewModel';
import SubscriptionContractResourceViewModel from '../../viewModel/resource/SubscriptionContractResourceViewModel';
import CreateSubscriptionContractRequestViewModel from '../../viewModel/resource/CreateSubscriptionContractRequestViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getSubscriptionArticles(subscriptionType: SubscriptionTypeResourceViewModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-subscription-articles'] ?? 'Subscriptions/{subscriptionType}/Articles';
    endpointPath = endpointPath.replace(/\{subscriptionType\}/g, `${subscriptionType}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new SubscriptionArticleResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async getSubscriptionContract(subscriptionType: SubscriptionTypeResourceViewModel, userId: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-subscription-contract'] ?? 'Subscriptions/{subscriptionType}/{userId}';
    endpointPath = endpointPath.replace(/\{subscriptionType\}/g, `${subscriptionType}`);
    endpointPath = endpointPath.replace(/\{userId\}/g, `${userId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new SubscriptionContractResourceViewModel().fromDTO(dto);
  },
  /**  */
  async getInitialSubscriptionRequest(request: GetInitialSubscriptionRequestRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-initial-subscription-request'] ?? 'Subscriptions/GetInitialRequest';
    const response = await service.get<any>(endpointPath, {
      query: {
        userId: requestDTO.userId,
      },
      ...config,
    });
    const dto = response.data.result;
    return new CreateSubscriptionContractRequestViewModel().fromDTO(dto);
  },
  /**  */
  async addSubscription(subscriptionType: SubscriptionTypeResourceViewModel, model: CreateSubscriptionContractRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-add-subscription'] ?? 'Subscriptions/{subscriptionType}';
    endpointPath = endpointPath.replace(/\{subscriptionType\}/g, `${subscriptionType}`);
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
});
