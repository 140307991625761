// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ContactSalutationTypeResourceViewModel from '../../../../viewModel/resource/ContactSalutationTypeResourceViewModel';
import AccommodationResourceViewModel from '../../../../viewModel/resource/AccommodationResourceViewModel';
import GastronomyResourceViewModel from '../../../../viewModel/resource/GastronomyResourceViewModel';
import MembershipBusinessResourceDTO from '../../dto/resource/MembershipBusinessResourceDTO';

export default abstract class MembershipBusinessResourceModel extends BaseModel<MembershipBusinessResourceDTO> {
  /**
  */
  get isCompany() { return this.dto.isCompany; }
  set isCompany(value) { this.dto.isCompany = value; }
  /**
  */
  get isCompanyRegistered() { return this.dto.isCompanyRegistered; }
  set isCompanyRegistered(value) { this.dto.isCompanyRegistered = value; }
  /**
  */
  get companyRegisterNr() { return this.dto.companyRegisterNr; }
  set companyRegisterNr(value) { this.dto.companyRegisterNr = value; }
  /**
  * @type {uuid}
  */
  get companyLegalFormId() { return this.dto.companyLegalFormId; }
  set companyLegalFormId(value) { this.dto.companyLegalFormId = value; }
  /**
  */
  get companyName() { return this.dto.companyName; }
  set companyName(value) { this.dto.companyName = value; }
  /**
  * @type {int32}
  */
  get salutation() { return this.dto.salutation; }
  set salutation(value) { this.dto.salutation = value; }
  /**
  */
  get firstName() { return this.dto.firstName; }
  set firstName(value) { this.dto.firstName = value; }
  /**
  */
  get lastName() { return this.dto.lastName; }
  set lastName(value) { this.dto.lastName = value; }
  /**
  * @type {date-time}
  */
  get dateOfBirth() { return this.dto.dateOfBirth; }
  set dateOfBirth(value) { this.dto.dateOfBirth = value; }
  /**
  */
  get eMail() { return this.dto.eMail; }
  set eMail(value) { this.dto.eMail = value; }
  /**
  */
  get phoneNumber() { return this.dto.phoneNumber; }
  set phoneNumber(value) { this.dto.phoneNumber = value; }
  /**
  */
  get mobilePhoneNumber() { return this.dto.mobilePhoneNumber; }
  set mobilePhoneNumber(value) { this.dto.mobilePhoneNumber = value; }
  /**
  */
  get street() { return this.dto.street; }
  set street(value) { this.dto.street = value; }
  /**
  */
  get houseNumber() { return this.dto.houseNumber; }
  set houseNumber(value) { this.dto.houseNumber = value; }
  /**
  */
  get addressLine1() { return this.dto.addressLine1; }
  set addressLine1(value) { this.dto.addressLine1 = value; }
  /**
  */
  get poBox() { return this.dto.poBox; }
  set poBox(value) { this.dto.poBox = value; }
  /**
  */
  get postalCode() { return this.dto.postalCode; }
  set postalCode(value) { this.dto.postalCode = value; }
  /**
  */
  get city() { return this.dto.city; }
  set city(value) { this.dto.city = value; }
  /**
  */
  get countryIso2() { return this.dto.countryIso2; }
  set countryIso2(value) { this.dto.countryIso2 = value; }
  /**
  */
  get languageIso2() { return this.dto.languageIso2; }
  set languageIso2(value) { this.dto.languageIso2 = value; }
  /**
  */
  get website() { return this.dto.website; }
  set website(value) { this.dto.website = value; }
  /**
  * @type {uuid}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  * @type {date-time}
  */
  get startDate() { return this.dto.startDate; }
  set startDate(value) { this.dto.startDate = value; }
  /**
  */
  get name() { return this.dto.name; }
  set name(value) { this.dto.name = value; }
  /**
  */
  get isActive() { return this.dto.isActive; }
  set isActive(value) { this.dto.isActive = value; }
  /**
  */
  get isTeachingApprentices() { return this.dto.isTeachingApprentices; }
  set isTeachingApprentices(value) { this.dto.isTeachingApprentices = value; }
  /**
  */
  get isSeasonal() { return this.dto.isSeasonal; }
  set isSeasonal(value) { this.dto.isSeasonal = value; }
  /**
  */
  get accommodation() { return this.getObjectModel(() => this.dto.accommodation, AccommodationResourceViewModel)!; }
  set accommodation(value) { this.setObjectModel(() => this.dto.accommodation, value); }
  /**
  */
  get gastronomy() { return this.getObjectModel(() => this.dto.gastronomy, GastronomyResourceViewModel)!; }
  set gastronomy(value) { this.setObjectModel(() => this.dto.gastronomy, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    value = queries.isCompany === 'true' ? true : queries.isCompany;
    value = queries.isCompany === 'false' ? false : undefined;
    this.dto.isCompany = value;
    value = queries.isCompanyRegistered === 'true' ? true : queries.isCompanyRegistered;
    value = queries.isCompanyRegistered === 'false' ? false : undefined;
    this.dto.isCompanyRegistered = value;
    this.dto.companyRegisterNr = queries.companyRegisterNr;
    this.dto.companyLegalFormId = queries.companyLegalFormId;
    this.dto.companyName = queries.companyName;
    this.dto.firstName = queries.firstName;
    this.dto.lastName = queries.lastName;
    this.dto.dateOfBirth = queries.dateOfBirth;
    this.dto.eMail = queries.eMail;
    this.dto.phoneNumber = queries.phoneNumber;
    this.dto.mobilePhoneNumber = queries.mobilePhoneNumber;
    this.dto.street = queries.street;
    this.dto.houseNumber = queries.houseNumber;
    this.dto.addressLine1 = queries.addressLine1;
    this.dto.poBox = queries.poBox;
    this.dto.postalCode = queries.postalCode;
    this.dto.city = queries.city;
    this.dto.countryIso2 = queries.countryIso2;
    this.dto.languageIso2 = queries.languageIso2;
    this.dto.website = queries.website;
    this.dto.id = queries.id;
    this.dto.startDate = queries.startDate;
    this.dto.name = queries.name;
    value = queries.isActive === 'true' ? true : queries.isActive;
    value = queries.isActive === 'false' ? false : undefined;
    this.dto.isActive = value;
    value = queries.isTeachingApprentices === 'true' ? true : queries.isTeachingApprentices;
    value = queries.isTeachingApprentices === 'false' ? false : undefined;
    this.dto.isTeachingApprentices = value;
    value = queries.isSeasonal === 'true' ? true : queries.isSeasonal;
    value = queries.isSeasonal === 'false' ? false : undefined;
    this.dto.isSeasonal = value;
  }
}
