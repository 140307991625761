import Vue from 'vue';
import CorrespondenceContactResourceModel from '../../generated/types/model/resource/CorrespondenceContactResourceModel';
import utils from '../utils';
import RequestSummaryResourceViewModel from './RequestSummaryResourceViewModel';

export default class CorrespondenceContactResourceViewModel extends CorrespondenceContactResourceModel {
  protected transformToDTO() {}
  protected transformFromDTO() {}

  get summary(): RequestSummaryResourceViewModel {
    if (this.isEqualToDefaultAddress) {
      return new RequestSummaryResourceViewModel().fromDTO({
        sections: [
          {
            items: [
              { label: Vue.$t('page.memberData.text.isEqualToDefaultAddress') },
            ],
          },
        ],
      });
    }
    return new RequestSummaryResourceViewModel().fromDTO({
      sections: [
        // {
        //   items: [
        //     {
        //       ...utils.insertIf(!!this.customerNumber, {
        //         label: Vue.$t('page.memberData.table.memberNumber'),
        //         displayValue: this.customerNumber,
        //       }),
        //     },
        //   ],
        // },
        {
          // title: Vue.$t('form.address.address'),
          items: [
            ...this.address.getSummaryItems(),
          ],
        },
      ],
    });
  }
}
