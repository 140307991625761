// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import PaginationDataResourceDTO from '../../dto/resource/PaginationDataResourceDTO';

export default abstract class PaginationDataResourceModel extends BaseModel<PaginationDataResourceDTO> {
  /**
  * @type {int32}
  */
  get totalCount() { return this.dto.totalCount; }
  set totalCount(value) { this.dto.totalCount = value; }
  /**
  * @type {int32}
  */
  get pageSize() { return this.dto.pageSize; }
  set pageSize(value) { this.dto.pageSize = value; }
  /**
  * @type {int32}
  */
  get currentPage() { return this.dto.currentPage; }
  set currentPage(value) { this.dto.currentPage = value; }
  /**
  * @type {int32}
  */
  get totalPages() { return this.dto.totalPages; }
  /**
  */
  get previousPage() { return this.dto.previousPage; }
  /**
  */
  get nextPage() { return this.dto.nextPage; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    value = Number.parseFloat(queries.totalCount);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.totalCount = value;
    value = Number.parseFloat(queries.pageSize);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.pageSize = value;
    value = Number.parseFloat(queries.currentPage);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.currentPage = value;
  }
}
