import coreRoutes from '@glittr/frontend-core/src/routes';
import Home from './views/pages/index.vue';

export default {

  mode: 'history',
  base: process.env!.BASE_URL,
  routes: [
    // #region redirects
    // redirects from old to new link because old links are still circulating
    {
      path: '/gs/rosetta',
      name: 'redirect-dashboard',
      redirect: '/gs/memberPortal',
    },
    {
      path: '/gs/rosetta/dynamic-form-test',
      name: 'redirect-dynamic-form-test',
      redirect: '/gs/memberPortal/dynamic-form-test',
    },
    {
      path: '/gs/rosetta/member-data',
      name: 'redirect-member-data',
      redirect: '/gs/memberPortal/member-data',
    },
    {
      path: '/gs/rosetta/member-data/member-data-detail',
      name: 'redirect-member-data-detail',
      redirect: '/gs/memberPortal/member-data/member-data-detail',
    },
    {
      path: '/gs/rosetta/member-data/businesses',
      name: 'redirect-businesses-list',
      redirect: '/gs/memberPortal/member-data/businesses',
    },
    {
      path: '/gs/rosetta/member-data/businesses/:id',
      name: 'redirect-businesses-detail',
      redirect: '/gs/memberPortal/member-data/businesses/:id',
    },
    {
      path: '/gs/rosetta/member-data/documents',
      name: 'redirect-documents-list',
      redirect: '/gs/memberPortal/member-data/documents',
    },
    {
      path: '/gs/rosetta/member-data/change-member-address',
      name: 'redirect-change-member-address',
      redirect: '/gs/memberPortal/member-data/change-member-address',
    },
    {
      path: '/gs/rosetta/legal-service',
      name: 'redirect-legal-service',
      redirect: '/gs/memberPortal/legal-service',
    },
    {
      path: '/gs/rosetta/legal-service/legal-enquiry-overview',
      name: 'redirect-legal-service-overview',
      redirect: '/gs/memberPortal/legal-service/legal-enquiry-overview',
    },
    {
      path: '/gs/rosetta/legal-service/send-legal-enquiry',
      name: 'redirect-legal-service-send-enquiry',
      redirect: '/gs/memberPortal/legal-service/send-legal-enquiry',
    },
    {
      path: '/gs/rosetta/legal-service/legal-enquiry-sent',
      name: 'redirect-legal-service-enquiry-sent',
      redirect: '/gs/memberPortal/legal-service/legal-enquiry-sent',
    },
    {
      path: '/gs/rosetta/gastro-journal',
      name: 'redirect-gastro-journal',
      redirect: '/gs/memberPortal/gastro-journal',
    },
    {
      path: '/gs/rosetta/gastro-journal/subscription/detail',
      name: 'redirect-gastro-journal-subscription-detail',
      redirect: '/gs/memberPortal/gastro-journal/subscription/detail',
    },
    {
      path: '/gs/rosetta/gastro-journal/subscription/forward',
      name: 'redirect-gastro-journal-subscription-forward',
      redirect: '/gs/memberPortal/gastro-journal/subscription/forward',
    },
    {
      path: '/gs/rosetta/gastro-journal/subscription/interrupt',
      name: 'redirect-gastro-journal-subscription-interrupt',
      redirect: '/gs/memberPortal/gastro-journal/subscription/interrupt',
    },
    {
      path: '/gs/rosetta/gastro-journal/subscription/list',
      name: 'redirect-gastro-journal-subscription-list',
      redirect: '/gs/memberPortal/gastro-journal/subscription/list',
    },
    {
      path: '/gs/rosetta/gastro-journal/subscription/pause',
      name: 'redirect-gastro-journal-subscription-pause',
      redirect: '/gs/memberPortal/gastro-journal/subscription/pause',
    },
    {
      path: '/gs/rosetta/gastro-journal/subscription/terminate',
      name: 'redirect-gastro-journal-subscription-terminate',
      redirect: '/gs/memberPortal/gastro-journal/subscription/terminate',
    },
    {
      path: '/gs/rosetta/gastro-journal-publications',
      name: 'redirect-gastro-journal-publications',
      redirect: '/gs/memberPortal/gastro-journal-publications',
    },
    {
      path: '/gs/rosetta/gastro-professional',
      name: 'redirect-gastro-professional',
      redirect: '/gs/memberPortal/gastro-professional',
    },
    {
      path: '/gs/rosetta/gastro-professional/subscription/terminate',
      name: 'redirect-gastro-professional-terminate',
      redirect: '/gs/memberPortal/gastro-professional/subscription/terminate',
    },
    {
      path: '/gs/rosetta/gastro-professional/subscription/detail/',
      name: 'redirect-gastro-professional-detail',
      redirect: '/gs/memberPortal/gastro-professional/subscription/detail/',
    },
    {
      path: '/gs/rosetta/hotel-classification',
      name: 'redirect-hotel-classification',
      redirect: '/gs/memberPortal/hotel-classification',
    },
    {
      path: '/gs/rosetta/hotel-classification/list',
      name: 'redirect-hotel-classification-list',
      redirect: '/gs/memberPortal/hotel-classification/list',
    },
    {
      path: '/gs/rosetta/processes/create-membership',
      name: 'redirect-processes-create-membership',
      redirect: '/gs/memberPortal/processes/create-membership',
    },
    {
      path: '/gs/rosetta/processes/link-membership',
      name: 'redirect-processes-link-membership',
      redirect: '/gs/memberPortal/processes/link-membership',
    },
    {
      path: '/gs/rosetta/processes/classify-hotel',
      name: 'redirect-processes-classify-hotel',
      redirect: '/gs/memberPortal/processes/classify-hotel',
    },
    {
      path: '/gs/rosetta/processes/classify-hotel/:id',
      name: 'redirect-processes-classify-hotel-edit',
      redirect: '/gs/memberPortal/processes/classify-hotel/:id',
    },
    {
      path: '/gs/rosetta/processes/new-gastro-journal-subscription',
      name: 'redirect-processes-new-gastro-journal-subscription',
      redirect: '/gs/memberPortal/processes/new-gastro-journal-subscription',
    },
    {
      path: '/gs/rosetta/processes/new-gastro-professional-subscription',
      name: 'redirect-processes-new-gastro-professional-subscription',
      redirect: '/gs/memberPortal/processes/new-gastro-professional-subscription',
    },
    {
      path: '/gs/rosetta/support',
      name: 'redirect-support-list',
      redirect: '/gs/memberPortal/support',
    },
    {
      path: '/gs/rosetta/support/feedback',
      name: 'redirect-support-feedback',
      redirect: '/gs/memberPortal/support/feedback',
    },
    // #endregion

    // #region routes
    // routes to pages
    {
      path: '/_exterior/login',
      name: 'gs-login',
      component: () => import('./views/pages/_exterior/login.vue'),
    },
    {
      path: '/_exterior/register',
      name: 'gs-registration',
      component: () => import('./views/pages/_exterior/registration.vue'),
    },
    {
      path: '/',
      name: 'gs-home',
      component: Home,
    },
    ...coreRoutes,
    {
      path: '/:tenant/:workspace/dynamic-form-test',
      name: 'dynamic-form-test',
      component: () => import('./views/pages/test/dynamicFormTest.vue'),
    },
    {
      path: '/:tenant/:workspace',
      name: 'dashboard',
      component: () => import('./views/pages/app/memberData/memberDataOverview.vue'),
    },
    {
      path: '/:tenant/:workspace/member-data',
      name: 'member-data',
      component: () => import('./views/pages/app/memberData/memberDataOverview.vue'),
    },
    {
      path: '/:tenant/:workspace/member-data/member-data-detail',
      name: 'member-data-detail',
      component: () => import('./views/pages/app/memberData/memberDataDetail.vue'),
    },
    {
      path: '/:tenant/:workspace/member-data/businesses',
      name: 'businesses-list',
      component: () => import('./views/pages/app/memberData/businessesList.vue'),
    },
    {
      path: '/:tenant/:workspace/member-data/businesses/:id',
      name: 'businesses-detail',
      component: () => import('./views/pages/app/memberData/businessDetail.vue'),
    },
    {
      path: '/:tenant/:workspace/member-data/documents',
      name: 'documents-list',
      component: () => import('./views/pages/app/memberData/documentsList.vue'),
    },
    {
      path: '/:tenant/:workspace/member-data/change-member-address',
      name: 'change-member-address',
      component: () => import('./views/pages/app/memberData/changeMemberAddress.vue'),
    },
    {
      path: '/:tenant/:workspace/legal-service',
      name: 'legal-service',
      component: () => import('./views/pages/app/legalService/legalService.vue'),
    },
    {
      path: '/:tenant/:workspace/legal-service/legal-enquiry-overview',
      name: 'legal-service-overview',
      component: () => import('./views/pages/app/legalService/legalEnquiryOverview.vue'),
    },
    {
      path: '/:tenant/:workspace/legal-service/send-legal-enquiry',
      name: 'legal-service-send-enquiry',
      component: () => import('./views/pages/app/legalService/sendLegalEnquiry.vue'),
    },
    {
      path: '/:tenant/:workspace/legal-service/legal-enquiry-sent',
      name: 'legal-service-enquiry-sent',
      component: () => import('./views/pages/app/legalService/legalEnquirySent.vue'),
    },
    {
      path: '/:tenant/:workspace/gastro-journal',
      name: 'gastro-journal',
      component: () => import('./views/pages/app/gastroJournal/gastroJournal.vue'),
    },
    {
      path: '/:tenant/:workspace/gastro-journal/subscription/detail',
      name: 'gastro-journal-subscription-detail',
      component: () => import('./views/pages/app/gastroJournal/gastroJournalSubscriptionDetail.vue'),
    },
    {
      path: '/:tenant/:workspace/gastro-journal/subscription/forward',
      name: 'gastro-journal-subscription-forward',
      component: () => import('./views/pages/app/gastroJournal/gastroJournalSubscriptionForward.vue'),
    },
    {
      path: '/:tenant/:workspace/gastro-journal/subscription/interrupt',
      name: 'gastro-journal-subscription-interrupt',
      component: () => import('./views/pages/app/gastroJournal/gastroJournalSubscriptionInterrupt.vue'),
    },
    {
      path: '/:tenant/:workspace/gastro-journal/subscription/list',
      name: 'gastro-journal-subscription-list',
      component: () => import('./views/pages/app/gastroJournal/gastroJournalSubscriptionList.vue'),
    },
    {
      path: '/:tenant/:workspace/gastro-journal/subscription/pause',
      name: 'gastro-journal-subscription-pause',
      component: () => import('./views/pages/app/gastroJournal/gastroJournalSubscriptionPause.vue'),
    },
    {
      path: '/:tenant/:workspace/gastro-journal/subscription/terminate',
      name: 'gastro-journal-subscription-terminate',
      component: () => import('./views/pages/app/gastroJournal/gastroJournalSubscriptionTerminate.vue'),
    },
    {
      path: '/:tenant/:workspace/gastro-journal-publications',
      name: 'gastro-journal-publications',
      component: () => import('./views/pages/app/gastroJournal/gastroJournalPublications.vue'),
    },
    {
      path: '/:tenant/:workspace/gastro-professional',
      name: 'gastro-professional',
      component: () => import('./views/pages/app/gastroProfessional/gastroProfessional.vue'),
    },
    {
      path: '/:tenant/:workspace/gastro-professional/subscription/terminate',
      name: 'gastro-professional-terminate',
      component: () => import('./views/pages/app/gastroProfessional/gastroProfessionalSubscriptionTerminate.vue'),
    },
    {
      path: '/:tenant/:workspace/gastro-professional/subscription/detail/',
      name: 'gastro-professional-detail',
      component: () => import('./views/pages/app/gastroProfessional/gastroProfessionalSubscriptionDetail.vue'),
    },
    {
      path: '/:tenant/:workspace/hotel-classification',
      name: 'hotel-classification',
      component: () => import('./views/pages/app/hotelClassification/hotelClassification.vue'),
    },
    {
      path: '/:tenant/:workspace/hotel-classification/list',
      name: 'hotel-classification-list',
      component: () => import('./views/pages/app/hotelClassification/hotelClassificationList.vue'),
    },
    {
      path: '/:tenant/:workspace/processes/create-membership',
      name: 'processes-create-membership',
      component: () => import('./views/pages/app/processes/createMembership/createMembership.vue'),
    },
    {
      path: '/:tenant/:workspace/processes/link-membership',
      name: 'processes-link-membership',
      component: () => import('./views/pages/app/processes/linkMembership/linkMembership.vue'),
    },
    {
      path: '/:tenant/:workspace/processes/classify-hotel',
      name: 'processes-classify-hotel',
      component: () => import('./views/pages/app/processes/classifyHotel/classifyHotel.vue'),
    },
    {
      path: '/:tenant/:workspace/processes/classify-hotel/:id',
      name: 'processes-classify-hotel-edit',
      component: () => import('./views/pages/app/processes/classifyHotel/classifyHotel.vue'),
    },
    {
      path: '/:tenant/:workspace/processes/new-gastro-journal-subscription',
      name: 'processes-new-gastro-journal-subscription',
      component: () => import('./views/pages/app/processes/newGastroJournalSubscription/newGastroJournalSubscription.vue'),
    },
    {
      path: '/:tenant/:workspace/processes/new-gastro-professional-subscription',
      name: 'processes-new-gastro-professional-subscription',
      component: () => import('./views/pages/app/processes/newGastroProfessionalSubscription/newGastroProfessionalSubscription.vue'),
    },
    {
      path: '/:tenant/:workspace/support',
      name: 'support-list',
      component: () => import('./views/pages/app/support/support.vue'),
    },
    {
      path: '/:tenant/:workspace/support/feedback',
      name: 'support-feedback',
      component: () => import('./views/pages/app/support/support-feedback.vue'),
    },
    // #endregion
  ],
};
