import Vue from 'vue';
import SubscriptionArticleResourceModel from '../../generated/types/model/resource/SubscriptionArticleResourceModel';

export default class SubscriptionArticleResourceViewModel extends SubscriptionArticleResourceModel {
  protected transformToDTO() {}
  protected transformFromDTO() {}

  get subscriptionDisplay() {
    return `${this.name} - ${Vue.$format.currency(this.unitPrice!, this.currency as any ?? 'CHF')}`;
  }

  get duration() {
    let months = '';
    let weeks = '';
    if (this.durationInMonths && this.durationInMonths > 0) {
      months = `${this.dto.durationInMonths} ${Vue.$t('page.newGastroJournalSubscription.table.months')}`;
    }
    if (this.durationInWeeks && this.durationInWeeks > 0) {
      weeks = `${this.dto.durationInWeeks} ${Vue.$t('page.newGastroJournalSubscription.table.weeks')}`;
    }
    return `${months} ${weeks}`;
  }
}
