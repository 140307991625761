import de from '@src/localization/translations/de/';
import it from '@src/localization/translations/it/';
import fr from '@src/localization/translations/fr/';

type TranslationType = typeof de;
declare module '@core/plugins/localization/translator' {
  interface TranslationMap extends TranslationType { }
}

export default {
  translations: {
    de,
    it,
    fr,
  },
};
