import Vue from 'vue';
import CreateMembershipRequestModel from '../../generated/types/model/resource/CreateMembershipRequestModel';
import RequestSummaryResourceViewModel from './RequestSummaryResourceViewModel';
import utils from '../utils';

export default class CreateMembershipRequestViewModel extends CreateMembershipRequestModel {
  protected transformToDTO() {
    this.dto.summary = this.summary.getDTO();
    if (this.memberContact.address.contactHasChanged) {
      this.memberContact.contactId = undefined;
    }
  }
  protected transformFromDTO() {}

  // Additional properties that need to be present in the DTO because the DTO gets saved to localStorage
  // TODO: Figure out a smart way to avoid setting the properties like this
  get additionalInformationRequired() { return (this.dto as any).additionalInformationRequired; }
  set additionalInformationRequired(value) { (this.dto as any).additionalInformationRequired = value; }
  get hasClassifiedBusinesses() { return (this.dto as any).hasClassifiedBusinesses; }
  set hasClassifiedBusinesses(value) { (this.dto as any).hasClassifiedBusinesses = value; }
  get onlinePaymentAllowed() { return (this.dto as any).onlinePaymentAllowed; }
  set onlinePaymentAllowed(value) { (this.dto as any).onlinePaymentAllowed = value; }
  get isDirectRegistration() { return (this.dto as any).isDirectRegistration; }
  set isDirectRegistration(value) { (this.dto as any).isDirectRegistration = value; }

  get payrollDisplayValue() { return (this.dto as any).payrollDisplayValue; }
  set payrollDisplayValue(value) { (this.dto as any).payrollDisplayValue = value; }
  get socialInsuranceDisplayValue() { return (this.dto as any).socialInsuranceDisplayValue; }
  set socialInsuranceDisplayValue(value) { (this.dto as any).socialInsuranceDisplayValue = value; }
  get certificateOfProficiencyCantonDisplayValue() { return (this.dto as any).certificateOfProficiencyCantonDisplayValue; }
  set certificateOfProficiencyCantonDisplayValue(value) { (this.dto as any).certificateOfProficiencyCantonDisplayValue = value; }
  // _____________________________________________________________________________

  get summary(): RequestSummaryResourceViewModel {
    return new RequestSummaryResourceViewModel().fromDTO({
      sections: [
        {
          title: Vue.$t('page.createMembership.label.stepTitles.2'),
          items: [
            {
              label: Vue.$t('page.createMembership.text.haveYouBeenAMemberDescription'),
              displayValue: this.isMembershipReEntry ? Vue.$t('app.yes') : Vue.$t('app.no'),
            },
          ],
        },

        {
          title: Vue.$t('page.createMembership.label.stepTitles.3'),
          items: [
            {
              label: Vue.$t('page.createMembership.table.postalcode'),
              displayValue: this.memberBusinessContact.address.postalCode,
            },
            {
              label: Vue.$t('page.createMembership.label.payRoll'),
              displayValue: this.payrollDisplayValue,
            },
            ...this.orderBasket.items.map((item : any) => ({
              label: item.caption!,
              displayValue: Vue.$format.currency(item.grossPrice!, item.currency as any ?? 'CHF'),
            })),
            {
              label: Vue.$t('page.createMembership.table.total'),
              displayValue: Vue.$format.currency(this.orderBasket.grossTotal!, this.orderBasket.currency as any ?? 'CHF'),
            },
          ],
        },

        {
          title: Vue.$t('page.createMembership.label.stepTitles.4'),
          items: [
            {
              label: Vue.$t('page.createMembership.label.desiredDateIfJoining'),
              displayValue: Vue.$format.localDate(this.entryDate!),
            },
            {
              label: Vue.$t('page.createMembership.label.socialInsurer'),
              displayValue: this.socialInsuranceDisplayValue,
            },
            {
              label: Vue.$t('page.createMembership.label.operatingLicence'),
              displayValue: this.hasOperatingLicence ? Vue.$t('app.yes') : Vue.$t('app.no'),
            },
            {
              ...utils.insertIf(this.additionalInformationRequired && this.hasOperatingLicence, {
                label: Vue.$t('page.createMembership.label.dateOperatingLicense'),
                displayValue: Vue.$format.localDate(this.dateOperatingLicenceIssued!),
              }),
            },
            {
              ...utils.insertIf(this.additionalInformationRequired && this.hasOperatingLicence, {
                label: Vue.$t('page.createMembership.label.holderOfOperatingLicence'),
                displayValue: this.operatingLicenceOwner,
              }),
            },
            {
              ...utils.insertIf(this.additionalInformationRequired, {
                label: Vue.$t('page.createMembership.label.certificateOfCapability'),
                displayValue: this.hasCertificateOfProficiency ? Vue.$t('app.yes') : Vue.$t('app.no'),
              }),
            },
            {
              ...utils.insertIf(this.additionalInformationRequired && this.hasCertificateOfProficiency, {
                label: Vue.$t('page.createMembership.label.dateOfCertificateOfProficiency'),
                displayValue: Vue.$format.localDate(this.dateCertificateOfProficiencyIssued!),
              }),
            },
            {
              ...utils.insertIf(this.additionalInformationRequired && this.hasCertificateOfProficiency, {
                label: Vue.$t('form.address.state'),
                displayValue: this.certificateOfProficiencyCantonDisplayValue,
              }),
            },
            {
              ...utils.insertIf(this.additionalInformationRequired && !this.hasCertificateOfProficiency, {
                label: Vue.$t('page.createMembership.label.coursePlanned'),
                displayValue: this.certificateOfProficiencyCourse,
              }),
            },
          ],
        },

        {
          title: Vue.$t('page.createMembership.label.stepTitles.5'),
          items: this.memberContact.address.getSummaryItems({ hideLanguageIso2: true }),
        },

        {
          title: Vue.$t('page.createMembership.label.stepTitles.6'),
          items: [
            {
              label: Vue.$t('page.createMembership.label.nameOfBusiness'),
              displayValue: this.membershipBusiness.companyName,
            },
            {
              label: Vue.$t('page.createMembership.table.businessOperations'),
              displayValue: Vue.$format.localDate(this.membershipBusiness.startDate!),
            },
            ...utils.insertIf(this.memberBusinessContact.isDifferentFromOwner!,
              this.memberBusinessContact.address.getSummaryItems({ hideLanguageIso2: true }),
              [{
                label: Vue.$t('form.address.address'),
                displayValue: Vue.$t('page.createMembership.text.isEqualToMembershipAddress'),
              }]),
            {
              label: Vue.$t('page.createMembership.table.teachingCompany'),
              displayValue: this.membershipBusiness.isTeachingApprentices ? Vue.$t('app.yes') : Vue.$t('app.no'),
            },
            {
              label: Vue.$t('page.createMembership.table.accommodation'),
              displayValue: this.membershipBusiness.accommodation.isAccommodation ? Vue.$t('app.yes') : Vue.$t('app.no'),
            },
            {
              label: Vue.$t('page.createMembership.table.seasonalBusiness'),
              displayValue: this.membershipBusiness.isSeasonal ? Vue.$t('app.yes') : Vue.$t('app.no'),
            },
          ],
        },

        {
          ...utils.insertIf(this.membershipBusiness.accommodation.isAccommodation === false, {
            title: Vue.$t('page.createMembership.label.stepTitles.7'),
            items: [
              ...this.membershipBusiness.gastronomy.summaryItems,
            ],
          }),
          ...utils.insertIf(this.membershipBusiness.accommodation.isAccommodation === true, {
            title: Vue.$t('page.createMembership.label.stepTitles.8'),
            items: [
              ...this.membershipBusiness.accommodation.summaryItems,
              {
                label: Vue.$t('page.createMembership.label.contactForClassification'),
                displayValue: this.acceptContactForClassification ? Vue.$t('app.yes') : Vue.$t('app.no'),
              },
            ],
          }),
        },

        {
          title: Vue.$t('page.createMembership.label.stepTitles.9'),
          items: [
            {
              label: Vue.$t('page.createMembership.table.correspondenceLanguage'),
              displayValue: this.memberCorrespondenceContact.languageIso2,
            },
            {
              ...utils.insertIf(this.memberCorrespondenceContact.isDifferentFromOwner!, {
                label: Vue.$t('page.createMembership.label.addressOfTrustee'),
                displayValue: this.memberCorrespondenceContact.isTrusteeAddress ? Vue.$t('app.yes') : Vue.$t('app.no'),
              }),
            },
            ...utils.insertIf(this.memberCorrespondenceContact.isDifferentFromOwner!,
              this.memberCorrespondenceContact.address.getSummaryItems({ hideLanguageIso2: true }),
              [{
                label: Vue.$t('form.address.address'),
                displayValue: Vue.$t('page.createMembership.text.isEqualToMembershipAddress'),
              }]),
          ],
        },
      ].map((section) => ({
        ...section,
        items: section?.items?.filter((item: any) => item.label !== undefined && item.displayValue !== undefined && item.displayValue !== null) ?? [],
      })),
    });
  }
}
