import Vue from 'vue';
import ContactResourceModel from '../../generated/types/model/resource/ContactResourceModel';
import utils from '../utils';
import RequestSummaryResourceViewModel from './RequestSummaryResourceViewModel';

export default class ContactResourceViewModel extends ContactResourceModel {
  protected transformToDTO() {}
  protected transformFromDTO() {}

  private get language() {
    return this.languageIso2;
  }

  get summary(): RequestSummaryResourceViewModel {
    return new RequestSummaryResourceViewModel().fromDTO({
      sections: [
        {
          title: Vue.$t('page.memberData.table.memberData'),
          items: [
            {
              ...utils.insertIf(!!this.customerNumber, {
                label: Vue.$t('page.memberData.table.memberNumber'),
                displayValue: this.customerNumber,
              }),
            },
            {
              ...utils.insertIf(!!this.language, {
                label: Vue.$t('page.memberData.table.language'),
                displayValue: this.language,
              }),
            },
          ],
        },
        {
          title: Vue.$t('form.address.address'),
          items: [
            ...this.address.getSummaryItems(),
          ],
        },
      ],
    });
  }
}
