





import Vue from 'vue';

export default Vue.extend({
  layout: 'layout-exterior',
  auth: true,
  beforeMount() {
    this.$router.replace('/gs/memberPortal');
  },
});
