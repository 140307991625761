


































































import Vue from 'vue';
import BusinessUnitResourceViewModel from '../services/viewModel/resource/BusinessUnitResourceViewModel';

export default Vue.extend({
  props: {
    showPending: { default: false, type: Boolean },
    hasSubscription: { default: false, type: Boolean },
  },
  data: () => ({
    additionalProcessingText: undefined as string | undefined,
    errorResponse: undefined as any,
    isMembershipInactive: false,
    isMembershipProcessing: false,
    isMembershipActive: false,
    isMembershipBlocked: false,
    isMembershipExpired: false,
    isMembershipPending: false,
    isLoading: false,
    intervalHandle: undefined as number | undefined,
    stateUnit: new BusinessUnitResourceViewModel(),
  }),
  computed: {
    expiredAlertDismissed: {
      get(): boolean {
        return !this.$sessionStorage.get('expired-alert-dismissed');
      },
      set(value: boolean) {
        this.$sessionStorage.set('expired-alert-dismissed', value);
      },
    },
    hasVisibleContent() : boolean {
      const showingPending = this.showPending && this.isMembershipPending && !this.hasSubscription;
      return !showingPending && (this.isMembershipPending || this.isMembershipActive || this.hasSubscription || this.isMembershipInactive);
    },
  },
  async mounted() {
    this.isLoading = true;
    this.intervalHandle = setInterval(async () => {
      this.refreshUserData();
    }, 15000);

    try {
      await this.refreshUserData();
    } catch (error) {
      this.errorResponse = error;
    }

    if (!this.hasVisibleContent) {
      try {
        this.stateUnit = await this.$service.api.businessUnits.getMyBusinessUnit();
      } catch (error: any) {
        if (error.status === 404) {
          // Do nothing, user does not have a membership or businessunit cannot be found
        } else {
          this.errorResponse = error;
        }
      }

      const stateCode = this.stateUnit?.stateCode?.toLowerCase();
      const translateParams = {
        name: `${this.stateUnit.unitName}`,
        email: `${this.stateUnit.eMailAddress}`,
        phoneNumber: `${this.$format.phonePretty(this.stateUnit.phoneNumber!)}`,
      };
      const translateKey = `page.dashboard.text.membershipRequestIsProcessing.${stateCode}`;
      const processingText = this.$tAlt(translateKey, undefined as any, translateParams);
      this.additionalProcessingText = processingText;
    }

    this.isLoading = false;
  },
  beforeDestroy() {
    clearInterval(this.intervalHandle);
  },
  methods: {
    onExpiredAlertDismissed() {
      this.expiredAlertDismissed = true;
    },
    async refreshUserData() {
      if (this.$config.values['networking-write-to-console']) {
        console.log('[MEMBERSHIP-CHECK-WRAPPER] Refreshing token in order to check membership claim');
      }
      try {
        await this.$auth.refresh();
      } catch (error) {
        this.errorResponse = error;
      }
      const previousProcessing = this.isMembershipProcessing;
      this.isMembershipInactive = this.$service.api.memberships.getIsInactive() || this.$service.api.memberships.getIsExpired();
      this.isMembershipProcessing = this.$service.api.memberships.getIsProcessing();
      this.isMembershipActive = this.$service.api.memberships.getIsMember();
      this.isMembershipPending = this.$service.api.memberships.getIsPending();
      this.isMembershipBlocked = this.$service.api.memberships.getIsBlocked();
      this.isMembershipExpired = this.$service.api.memberships.getIsExpired();
      if (this.hasVisibleContent) {
        clearInterval(this.intervalHandle);
        if (previousProcessing) {
          window.location.reload();
        }
      }
    },
  },
});
