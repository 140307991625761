






























import Vue from 'vue';

export default Vue.extend({
  data: () => ({
    theme: 'glittr-pixel-light',
  }),
  computed: {
    isPwaButtonVisible(): boolean {
      return !!Vue.$store.values.showPwaInstallPromptFunction;
    },
  },
  async mounted() {
    // PWA
    const addBtn = document.querySelector('.pwa-add-button') as HTMLElement;
    if (addBtn && Vue.$store.values.showPwaInstallPromptFunction) {
      addBtn.addEventListener('click', Vue.$store.values.showPwaInstallPromptFunction);
    }
  },
});
