// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import CreateDynamicFormFieldRequestViewModel from '../../../../viewModel/resource/CreateDynamicFormFieldRequestViewModel';
import CreateDynamicFormFieldRequestDTO from '../../dto/resource/CreateDynamicFormFieldRequestDTO';

export default abstract class CreateDynamicFormFieldRequestModel extends BaseModel<CreateDynamicFormFieldRequestDTO> {
  /**
  * @type {uuid}
  */
  get dynamicFormFieldDefinitionId() { return this.dto.dynamicFormFieldDefinitionId; }
  set dynamicFormFieldDefinitionId(value) { this.dto.dynamicFormFieldDefinitionId = value; }
  /**
  */
  get value() { return this.dto.value; }
  set value(value) { this.dto.value = value; }
  /**
  */
  get groupFields() { return this.getArrayModels(() => this.dto.groupFields, CreateDynamicFormFieldRequestViewModel)!; }
  set groupFields(value) { this.setArrayModels(() => this.dto.groupFields, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    this.dto.dynamicFormFieldDefinitionId = queries.dynamicFormFieldDefinitionId;
  }
}
