// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkDTO from '../../dto/resource/ResourceLinkDTO';

export default abstract class ResourceLinkModel extends BaseModel<ResourceLinkDTO> {
  /**
  */
  get href() { return this.dto.href; }
  set href(value) { this.dto.href = value; }
  /**
  */
  get rel() { return this.dto.rel; }
  set rel(value) { this.dto.rel = value; }
  /**
  */
  get method() { return this.dto.method; }
  set method(value) { this.dto.method = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    this.dto.href = queries.href;
    this.dto.rel = queries.rel;
    this.dto.method = queries.method;
  }
}
