import Vue from 'vue';
import GastronomyResourceModel from '../../generated/types/model/resource/GastronomyResourceModel';
import RequestSummaryItemResourceViewModel from './RequestSummaryItemResourceViewModel';

export default class GastronomyResourceViewModel extends GastronomyResourceModel {
  protected transformToDTO() {}
  protected transformFromDTO() {}

  get summaryItems(): RequestSummaryItemResourceViewModel[] {
    return [
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('page.createMembership.table.gastronomyType'),
        displayValue: this.businessTypeName,
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: `${Vue.$t('form.address.inside')} - ${Vue.$t('page.createMembership.table.seats')}`,
        displayValue: `${this.placesInHouse ?? 0}`,
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: `${Vue.$t('form.address.hall')} - ${Vue.$t('page.createMembership.table.seats')}`,
        displayValue: `${this.placesHall ?? 0}`,
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: `${Vue.$t('form.address.terrace')} - ${Vue.$t('page.createMembership.table.seats')}`,
        displayValue: `${this.placesTerrace ?? 0}`,
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('page.createMembership.table.totalNumberOfSeats'),
        displayValue: `${this.placesTotal ?? 0}`,
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('page.createMembership.table.wheelchairAccessible'),
        displayValue: this.isWheelchairAccessible ? Vue.$t('app.yes') : Vue.$t('app.no'),
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('page.createMembership.table.disabledWC'),
        displayValue: this.isRestRoomHandicappedAccessible ? Vue.$t('app.yes') : Vue.$t('app.no'),
      }),
      new RequestSummaryItemResourceViewModel().fromModel({
        label: Vue.$t('page.createMembership.table.fumoir'),
        displayValue: this.hasSmokingRoom ? Vue.$t('app.yes') : Vue.$t('app.no'),
      }),
    ].filter((item: any) => item.label !== undefined && item.displayValue !== undefined && item.displayValue !== null);
  }

  get placesTotal() {
    const placesHall = this.placesHall ?? 0;
    const placesInHouse = this.placesInHouse ?? 0;
    const placesTerrace = this.placesTerrace ?? 0;
    const placesTotal = parseInt(Number(placesHall) as any, 10) + parseInt(Number(placesInHouse) as any, 10) + parseInt(Number(placesTerrace) as any, 10);
    return placesTotal;
  }
}
