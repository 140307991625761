import Vue from 'vue';
import appServices from '../api/App';
import appLogsServices from '../api/AppLogs';
import appSystemServices from '../api/AppSystem';
import attributesServices from '../api/Attributes';
import authServices from '../api/Auth';
import businessUnitsServices from '../api/BusinessUnits';
import consultingCasesServices from '../api/ConsultingCases';
import contactIdMappingsServices from '../api/ContactIdMappings';
import documentsServices from '../api/Documents';
import dynamicFormsServices from '../api/DynamicForms';
import hotelClassificationsServices from '../api/HotelClassifications';
import integrationCallsServices from '../api/IntegrationCalls';
import integrationTasksServices from '../api/IntegrationTasks';
import locationsServices from '../api/Locations';
import meServices from '../api/Me';
import membershipsServices from '../api/Memberships';
import processesServices from '../api/Processes';
import subscriptionsServices from '../api/Subscriptions';
import tenantsServices from '../api/Tenants';
import usersServices from '../api/Users';

export default () => ({
  app: { ...appServices(Vue.$service) },
  appLogs: { ...appLogsServices(Vue.$service) },
  appSystem: { ...appSystemServices(Vue.$service) },
  attributes: { ...attributesServices(Vue.$service) },
  auth: { ...authServices(Vue.$service) },
  businessUnits: { ...businessUnitsServices(Vue.$service) },
  consultingCases: { ...consultingCasesServices(Vue.$service) },
  contactIdMappings: { ...contactIdMappingsServices(Vue.$service) },
  documents: { ...documentsServices(Vue.$service) },
  dynamicForms: { ...dynamicFormsServices(Vue.$service) },
  hotelClassifications: { ...hotelClassificationsServices(Vue.$service) },
  integrationCalls: { ...integrationCallsServices(Vue.$service) },
  integrationTasks: { ...integrationTasksServices(Vue.$service) },
  locations: { ...locationsServices(Vue.$service) },
  me: { ...meServices(Vue.$service) },
  memberships: { ...membershipsServices(Vue.$service) },
  processes: { ...processesServices(Vue.$service) },
  subscriptions: { ...subscriptionsServices(Vue.$service) },
  tenants: { ...tenantsServices(Vue.$service) },
  users: { ...usersServices(Vue.$service) },
});
