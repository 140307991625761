




































import Vue from 'vue';
import ContactResourceViewModel from '../services/viewModel/resource/ContactResourceViewModel';
import utils from '../services/viewModel/utils';

export default Vue.extend({
  props: {
    value: { type: Object, default: () => ({}) },
    tableDisplay: { type: Boolean, default: false },
    showLanguage: { type: Boolean, default: false },
    notDifferentText: { type: String, default: undefined },
    showCustomerNumber: { type: Boolean, default: false },
  },
  computed: {
    listItems(): {label?: string, value?: string, divider?: boolean, labelOptional?: boolean}[] {
      if (this.ifIsNotDifferent) {
        if (this.notDifferentText) {
          return [{ value: this.notDifferentText }];
        }
        return [{ value: this.$t('form.address.notDifferent') }];
      }

      return [
        {
          ...utils.insertIf(!!this.value.customerNumber && this.showCustomerNumber, {
            label: this.$t('page.memberData.table.memberNumber'),
            value: this.value.customerNumber,
          }),
        },
        {
          ...utils.insertIf(!!this.value.customerNumber && this.showCustomerNumber, {
            divider: true,
          }),
        },
        {
          ...utils.insertIf(!!this.language && this.showLanguage, {
            label: this.$t('page.memberData.table.language'),
            value: this.language,
          }),
        },
        {
          ...utils.insertIf(!!this.isTrusteeDisplay, {
            label: this.$t('page.memberData.table.isTrustee'),
            value: this.isTrusteeDisplay,
          }),
        },
        {
          ...utils.insertIf((!!this.language && this.showLanguage) || !!this.isTrusteeDisplay, {
            divider: true,
          }),
        },
        {
          ...utils.insertIf(!!this.address.companyName, {
            label: this.$t('form.address.company'),
            value: this.address.companyName,
            labelOptional: true,
          }),
        },
        {
          ...utils.insertIf(!!this.address.companyLegalFormDisplayValue, {
            label: this.$t('page.createMembership.table.legalForm'),
            value: this.address.companyLegalFormDisplayValue,
          }),
        },
        {
          ...utils.insertIf(!!this.address.isCompanyRegistered, {
            label: this.$t('page.createMembership.label.registerUID'),
            value: this.address.companyRegisterNr,
            labelOptional: true,
          }),
        },
        {
          ...utils.insertIf(!!this.salutation, {
            label: this.$t('form.address.salutation'),
            value: this.salutation,
            labelOptional: true,
          }),
        },
        {
          ...utils.insertIf(!!this.address.firstName, {
            label: this.$t('form.address.name'),
            value: `${this.address.firstName} ${this.address.lastName}`,
            labelOptional: true,
          }),
        },
        {
          ...utils.insertIf(!!this.address.street, {
            label: this.$t('form.address.street'),
            value: `${this.address.street} ${this.address.houseNumber}`,
            labelOptional: true,
          }),
        },
        {
          ...utils.insertIf(!!this.address.addressLine1, {
            label: this.$t('form.address.additionalAddress'),
            value: this.address.addressLine1,
            labelOptional: true,
          }),
        },
        {
          ...utils.insertIf(!!this.address.poBox, {
            label: this.$t('form.address.post'),
            value: this.address.poBox,
            labelOptional: true,
          }),
        },
        {
          ...utils.insertIf(!!this.address.postalCode, {
            label: `${this.$t('form.address.plz')} / ${this.$t('form.address.place')}`,
            value: `${this.address.postalCode} ${this.address.city}`,
            labelOptional: true,
          }),
        },
        {
          ...utils.insertIf(!!this.address.phoneNumber || !!this.address.mobilePhoneNumber || !!this.address.eMail || !!this.address.website || !!this.address.dateOfBirth, {
            divider: true,
          }),
        },
        {
          ...utils.insertIf(!!this.address.phoneNumber, {
            label: this.$t('form.address.phone'),
            value: this.$format.phonePretty(this.address.phoneNumber!),
          }),
        },
        {
          ...utils.insertIf(!!this.address.mobilePhoneNumber, {
            label: this.$t('form.address.mobile'),
            value: this.$format.phonePretty(this.address.mobilePhoneNumber!),
          }),
        },
        {
          ...utils.insertIf(!!this.address.eMail, {
            label: this.$t('form.address.email'),
            value: this.address.eMail,
          }),
        },
        {
          ...utils.insertIf(!!this.address.website, {
            label: this.$t('form.address.webAddress'),
            value: this.address.website,
          }),
        },
        {
          ...utils.insertIf(!!this.address.dateOfBirth, {
            label: this.$t('page.memberData.table.birthDate'),
            value: this.$format.localDate(this.address.dateOfBirth!),
          }),
        },
      ];
    },
    language(): string | undefined {
      const value = this.value as any ?? {};
      return value.languageIso2;
    },
    isTrusteeDisplay(): string | undefined {
      const value = this.value as any ?? {};
      if (value.isTrusteeAddress === true) {
        return this.$t('app.yes');
      } if (value.isTrusteeAddress === false) {
        return this.$t('app.no');
      }
      return undefined;
    },
    salutation(): string | undefined {
      const salutationEnum = this.address.salutation;
      if (!salutationEnum) {
        return undefined;
      }
      return this.$tEnum('form.address.salutationEnum', salutationEnum);
    },
    ifIsNotDifferent(): boolean {
      const value = this.value as any ?? {};
      return value.isDifferentFromOwner === false || value.isEqualToDefaultAddress;
    },
    address(): ContactResourceViewModel['address'] {
      const value = this.value as any;
      return value?.address ?? this.value ?? {};
    },
  },
});
