// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ContactResourceViewModel from '../../../../viewModel/resource/ContactResourceViewModel';
import CorrespondenceContactResourceViewModel from '../../../../viewModel/resource/CorrespondenceContactResourceViewModel';
import MembershipResourceDTO from '../../dto/resource/MembershipResourceDTO';

export default abstract class MembershipResourceModel extends BaseModel<MembershipResourceDTO> {
  /**
  */
  get membershipNumber() { return this.dto.membershipNumber; }
  set membershipNumber(value) { this.dto.membershipNumber = value; }
  /**
  * @type {date-time}
  */
  get entryDate() { return this.dto.entryDate; }
  set entryDate(value) { this.dto.entryDate = value; }
  /**
  * @type {date-time}
  */
  get registrationDate() { return this.dto.registrationDate; }
  set registrationDate(value) { this.dto.registrationDate = value; }
  /**
  * @type {int32}
  */
  get payrollCategoryId() { return this.dto.payrollCategoryId; }
  set payrollCategoryId(value) { this.dto.payrollCategoryId = value; }
  /**
  */
  get isActive() { return this.dto.isActive; }
  set isActive(value) { this.dto.isActive = value; }
  /**
  * @type {int32}
  */
  get membershipTemplateNumber() { return this.dto.membershipTemplateNumber; }
  set membershipTemplateNumber(value) { this.dto.membershipTemplateNumber = value; }
  /**
  */
  get businessContact() { return this.getObjectModel(() => this.dto.businessContact, ContactResourceViewModel)!; }
  set businessContact(value) { this.setObjectModel(() => this.dto.businessContact, value); }
  /**
  */
  get memberContact() { return this.getObjectModel(() => this.dto.memberContact, ContactResourceViewModel)!; }
  set memberContact(value) { this.setObjectModel(() => this.dto.memberContact, value); }
  /**
  */
  get partnerContact() { return this.getObjectModel(() => this.dto.partnerContact, ContactResourceViewModel)!; }
  set partnerContact(value) { this.setObjectModel(() => this.dto.partnerContact, value); }
  /**
  */
  get billingContact() { return this.getObjectModel(() => this.dto.billingContact, CorrespondenceContactResourceViewModel)!; }
  set billingContact(value) { this.setObjectModel(() => this.dto.billingContact, value); }
  /**
  */
  get debtorContact() { return this.getObjectModel(() => this.dto.debtorContact, ContactResourceViewModel)!; }
  set debtorContact(value) { this.setObjectModel(() => this.dto.debtorContact, value); }
  /**
  */
  get businessUnitContact() { return this.getObjectModel(() => this.dto.businessUnitContact, ContactResourceViewModel)!; }
  set businessUnitContact(value) { this.setObjectModel(() => this.dto.businessUnitContact, value); }
  /**
  */
  get regionContact() { return this.getObjectModel(() => this.dto.regionContact, ContactResourceViewModel)!; }
  set regionContact(value) { this.setObjectModel(() => this.dto.regionContact, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    this.dto.membershipNumber = queries.membershipNumber;
    this.dto.entryDate = queries.entryDate;
    this.dto.registrationDate = queries.registrationDate;
    value = Number.parseFloat(queries.payrollCategoryId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.payrollCategoryId = value;
    value = queries.isActive === 'true' ? true : queries.isActive;
    value = queries.isActive === 'false' ? false : undefined;
    this.dto.isActive = value;
    value = Number.parseFloat(queries.membershipTemplateNumber);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.membershipTemplateNumber = value;
  }
}
