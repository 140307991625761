







































import Vue from 'vue';
import { StepData } from './rs-wizard.vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
    proceedDisabled: { type: Boolean, default: false },
  },
  data: () => ({
    stepData: {} as Partial<StepData>,
    // eslint-disable-next-line no-unused-vars
    proceedFunction: undefined as undefined | (() => void),
    backFunction: undefined as undefined | (() => void),
    // eslint-disable-next-line no-unused-vars
    completeFunction: undefined as undefined | (() => void),
    isLastStep: false,
    isFirstStep: false,
    isBackDisabled: false,
    errorResponse: undefined,
    isLoading: false,
  }),
  methods: {
    goBack() {
      if (this.backFunction) {
        this.backFunction();
      }
    },
    proceedOrComplete() {
      if (this.isLastStep) {
        this.complete();
      } else {
        this.proceed();
      }
    },
    proceed() {
      if (typeof this.proceedFunction === 'function') {
        this.proceedFunction();
      }
    },
    complete() {
      if (typeof this.completeFunction === 'function') {
        this.completeFunction();
      }
    },
  },
});
