import login from './page/_exterior/login.json';
import registration from './page/_exterior/registration.json';

import app from './global/app.json';
import navigation from './global/navigation.json';
import links from './global/links.json';
import images from './global/images.json';

import memberData from './page/memberdata.json';
import dashboard from './page/dashboard.json';
import createMembership from './page/create-membership.json';
import linkMembership from './page/link-membership.json';
import gastroJournal from './page/gastro-journal.json';
import gastroProfessional from './page/gastro-professional.json';
import hotelClassification from './page/hotel-classification.json';
import newGastroJournalSubscription from './page/new-gastro-journal-subscription.json';
import newGastroProfessionalSubscription from './page/new-gastro-professional-subscription.json';
import legalService from './page/legal-service.json';
import applog from './page/applog.json';
import user from './page/user.json';
import health from './page/health.json';
import appAbout from './page/app-about.json';
import address from './form/address.json';
import showError from './page/showError.json';
import classifyHotel from './page/classify-hotel.json';
import subscription from './page/subscription.json';
import support from './page/support.json';
import supportFeedback from './page/support-feedback.json';

export default {
  app,
  navigation,
  links,
  images,
  form: {
    address,
  },
  page: {
    login,
    registration,
    memberData,
    dashboard,
    createMembership,
    linkMembership,
    gastroJournal,
    gastroProfessional,
    hotelClassification,
    newGastroJournalSubscription,
    newGastroProfessionalSubscription,
    legalService,
    applog,
    user,
    health,
    appAbout,
    showError,
    classifyHotel,
    subscription,
    support,
    supportFeedback,
  },
};
