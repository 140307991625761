// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import SubscriptionTypeResourceViewModel from '../../../../viewModel/resource/SubscriptionTypeResourceViewModel';
import ContactResourceViewModel from '../../../../viewModel/resource/ContactResourceViewModel';
import DeliveryAddressResourceViewModel from '../../../../viewModel/resource/DeliveryAddressResourceViewModel';
import BasketResourceViewModel from '../../../../viewModel/resource/BasketResourceViewModel';
import RequestSummaryResourceViewModel from '../../../../viewModel/resource/RequestSummaryResourceViewModel';
import CreateSubscriptionContractRequestDTO from '../../dto/resource/CreateSubscriptionContractRequestDTO';

export default abstract class CreateSubscriptionContractRequestModel extends BaseModel<CreateSubscriptionContractRequestDTO> {
  /**
  * @type {int64}
  */
  get userId() { return this.dto.userId; }
  set userId(value) { this.dto.userId = value; }
  /**
  * @type {int32}
  */
  get subscriptionType() { return this.dto.subscriptionType; }
  set subscriptionType(value) { this.dto.subscriptionType = value; }
  /**
  * @type {int32}
  */
  get subscriptionTemplateNumber() { return this.dto.subscriptionTemplateNumber; }
  set subscriptionTemplateNumber(value) { this.dto.subscriptionTemplateNumber = value; }
  /**
  * @type {date-time}
  */
  get entryDate() { return this.dto.entryDate; }
  set entryDate(value) { this.dto.entryDate = value; }
  /**
  */
  get isBillingContactReadonly() { return this.dto.isBillingContactReadonly; }
  set isBillingContactReadonly(value) { this.dto.isBillingContactReadonly = value; }
  /**
  */
  get billingContact() { return this.getObjectModel(() => this.dto.billingContact, ContactResourceViewModel)!; }
  set billingContact(value) { this.setObjectModel(() => this.dto.billingContact, value); }
  /**
  */
  get isDeliveryContactReadonly() { return this.dto.isDeliveryContactReadonly; }
  set isDeliveryContactReadonly(value) { this.dto.isDeliveryContactReadonly = value; }
  /**
  */
  get deliveryContact() { return this.getObjectModel(() => this.dto.deliveryContact, DeliveryAddressResourceViewModel)!; }
  set deliveryContact(value) { this.setObjectModel(() => this.dto.deliveryContact, value); }
  /**
  */
  get orderBasket() { return this.getObjectModel(() => this.dto.orderBasket, BasketResourceViewModel)!; }
  set orderBasket(value) { this.setObjectModel(() => this.dto.orderBasket, value); }
  /**
  */
  get summary() { return this.getObjectModel(() => this.dto.summary, RequestSummaryResourceViewModel)!; }
  set summary(value) { this.setObjectModel(() => this.dto.summary, value); }
  /**
  */
  get newsletter() { return this.dto.newsletter; }
  set newsletter(value) { this.dto.newsletter = value; }
  /**
  */
  get languageIso2() { return this.dto.languageIso2; }
  set languageIso2(value) { this.dto.languageIso2 = value; }
  /**
  */
  get acceptGdpr() { return this.dto.acceptGdpr; }
  set acceptGdpr(value) { this.dto.acceptGdpr = value; }
  /**
  */
  get acceptAgb() { return this.dto.acceptAgb; }
  set acceptAgb(value) { this.dto.acceptAgb = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    value = Number.parseFloat(queries.userId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.userId = value;
    value = Number.parseFloat(queries.subscriptionTemplateNumber);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.subscriptionTemplateNumber = value;
    this.dto.entryDate = queries.entryDate;
    value = queries.isBillingContactReadonly === 'true' ? true : queries.isBillingContactReadonly;
    value = queries.isBillingContactReadonly === 'false' ? false : undefined;
    this.dto.isBillingContactReadonly = value;
    value = queries.isDeliveryContactReadonly === 'true' ? true : queries.isDeliveryContactReadonly;
    value = queries.isDeliveryContactReadonly === 'false' ? false : undefined;
    this.dto.isDeliveryContactReadonly = value;
    value = queries.newsletter === 'true' ? true : queries.newsletter;
    value = queries.newsletter === 'false' ? false : undefined;
    this.dto.newsletter = value;
    this.dto.languageIso2 = queries.languageIso2;
    value = queries.acceptGdpr === 'true' ? true : queries.acceptGdpr;
    value = queries.acceptGdpr === 'false' ? false : undefined;
    this.dto.acceptGdpr = value;
    value = queries.acceptAgb === 'true' ? true : queries.acceptAgb;
    value = queries.acceptAgb === 'false' ? false : undefined;
    this.dto.acceptAgb = value;
  }
}
