// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import MembershipBusinessListResourceDTO from '../../dto/resource/MembershipBusinessListResourceDTO';

export default abstract class MembershipBusinessListResourceModel extends BaseModel<MembershipBusinessListResourceDTO> {
  /**
  * @type {uuid}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  */
  get isActive() { return this.dto.isActive; }
  set isActive(value) { this.dto.isActive = value; }
  /**
  */
  get name() { return this.dto.name; }
  set name(value) { this.dto.name = value; }
  /**
  */
  get city() { return this.dto.city; }
  set city(value) { this.dto.city = value; }
  /**
  */
  get postalCode() { return this.dto.postalCode; }
  set postalCode(value) { this.dto.postalCode = value; }
  /**
  */
  get categoryName() { return this.dto.categoryName; }
  set categoryName(value) { this.dto.categoryName = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    this.dto.id = queries.id;
    value = queries.isActive === 'true' ? true : queries.isActive;
    value = queries.isActive === 'false' ? false : undefined;
    this.dto.isActive = value;
    this.dto.name = queries.name;
    this.dto.city = queries.city;
    this.dto.postalCode = queries.postalCode;
    this.dto.categoryName = queries.categoryName;
  }
}
