






























































import Vue from 'vue';
import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import User from '@glittr/frontend-core/src/plugins/auth/user';
import rsWizardStep from './rs-wizard-step.vue';

export interface StepData<TModel = any> {
  title?: string,
  breadcrumb: string,
  schema?: IJSONSchema,
  disablePreviousSteps?: boolean,
  component: () => Promise<any>,
  // eslint-disable-next-line no-unused-vars
  condition?: (data: TModel)=>boolean,
  // eslint-disable-next-line no-unused-vars
  sendData?: boolean | ((data: any) => boolean),
  // eslint-disable-next-line no-unused-vars
  clearProgressAfterSend?: boolean | ((data: any) => boolean),
  // eslint-disable-next-line no-unused-vars
  clearProgressOnMounted?: boolean | ((data: any) => boolean),
}

export default Vue.extend({
  props: {
    title: { type: String, default: undefined },
    steps: { type: Array, default: () => [] },
    value: { type: Number, default: undefined, validator: (value: number) => value >= 0 },
    initializeData: { type: Function, default: () => ({}) },
    toOnComplete: { type: String, default: '/' },
    serviceFunction: { type: Function, default: async () => {} },
    modelClass: { type: undefined, default: undefined },
    resetProgress: { type: Boolean, default: false },
  },
  data: () => ({
    isLoading: false,
    childSteps: [] as InstanceType<typeof rsWizardStep>[],
    currentStepNr: 0,
    progressNr: 0,
    errorResponse: undefined,
    wizardData: undefined as BaseModel<any> | undefined,
  }),
  computed: {
    progressStorageKey(): string {
      return `${this.dataStorageKey}-progress`;
    },
    dataStorageKey(): string {
      const { version } = this.$version;
      const name = this.title;
      const user = this.$auth.user ?? {} as User;
      const userId = user.id ?? 'unknown';
      // Make progress unique to the app version and the user
      return `${version}-${userId}-${name}`;
    },
    currentStepData(): Partial<StepData> {
      if (!this.steps || this.currentStepNr < 0 || this.currentStepNr >= this.steps.length) {
        return {};
      }
      return this.steps[this.currentStepNr] as StepData;
    },
    translatedTitle(): string {
      if (!this.title) {
        return '';
      }
      return this.$tAlt(this.title, this.title);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value) {
          this.currentStepNr = this.value;
        }
      },
    },
    currentStepNr() {
      this.$emit('input', this.currentStepNr);
    },
  },
  async mounted() {
    this.isLoading = true;
    try {
      if (this.resetProgress) {
        this.clearProgress();
      }
      // Make sure the user data is available
      // TODO: User data should always be available
      await this.$auth.getUser();
      await this.loadWizardData();

      this.saveWizardData();
      this.checkAndClearProgressOnMount();
    } catch (error: any) {
      this.errorResponse = error;
    }
    this.$nextTick(() => {
      this.isLoading = false;
    });
  },
  methods: {
    closeWizard() {
      this.$router.back();
    },
    onChildRegistered(child: Vue) {
      const stepComponent = child.$parent as InstanceType<typeof rsWizardStep>;
      stepComponent.backFunction = this.backOneStep;
      stepComponent.proceedFunction = this.saveAndProceed;
      stepComponent.completeFunction = () => this.completeWizard(this.toOnComplete);
      stepComponent.isFirstStep = this.currentStepNr === 0;
      stepComponent.isBackDisabled = !!stepComponent.isFirstStep || !!this.currentStepData.disablePreviousSteps;
      stepComponent.isLastStep = this.currentStepNr >= this.steps.length - 1;
      stepComponent.stepData = this.currentStepData as StepData;
      this.childSteps.push(stepComponent);
    },
    onChildUnregister(child: Vue) {
      const stepComponent = child.$parent as InstanceType<typeof rsWizardStep>;
      this.childSteps = this.childSteps.filter((step) => step !== stepComponent);
    },
    isStepDisabled(index: number) {
      if (this.progressNr < index) {
        return true;
      }
      if (this.currentStepData.disablePreviousSteps) {
        if (this.progressNr > index) {
          return true;
        }
      }
      return false;
    },
    isStepVisible(step: StepData) {
      if (typeof step.condition === 'function') {
        const isVisible = step.condition(this.wizardData ?? {});
        return isVisible;
      }
      return true;
    },
    async loadWizardData() {
      try {
        const cachedData = this.$sessionStorage.get<{}>(this.dataStorageKey);
        // eslint-disable-next-line no-unused-vars
        const ModelClass = this.modelClass as unknown as new () => any;
        if (!ModelClass) {
          console.error('No model-class set! A model-class needs to be supplied to every wizard');
        }
        if (cachedData) {
          const cachedProgress = this.$sessionStorage.get<number>(this.progressStorageKey)!;
          this.progressNr = cachedProgress ?? 0;
          this.currentStepNr = this.progressNr;
          this.wizardData = new ModelClass().fromDTO(cachedData);
        } else {
          const init = this.initializeData as () => Promise<{}>;
          this.wizardData = new ModelClass().fromModel(await init());
        }
      } catch (error) {
        console.error('Unable to load previous wizard data, the progress will be reset.');
        console.error(error);
        // eslint-disable-next-line no-unused-vars
        const ModelClass = this.modelClass as unknown as new () => any;
        const init = this.initializeData as () => Promise<{}>;
        this.wizardData = new ModelClass().fromModel(await init());
      }
    },
    saveWizardData() {
      const dto = this.wizardData?.getDTO();
      this.$sessionStorage.set(this.dataStorageKey, dto);
    },
    async callServiceFunction() {
      try {
        this.childSteps.forEach((step) => {
          step.errorResponse = undefined;
          step.isLoading = true;
        });
        // eslint-disable-next-line no-unused-vars
        await (this.serviceFunction as (data: any)=>Promise<any>)(this.wizardData);
      } catch (e: any) {
        this.childSteps.forEach((step) => {
          step.errorResponse = e;
          step.isLoading = false;
        });
        return false;
      }
      this.childSteps.forEach((step) => {
        step.isLoading = false;
      });
      return true;
    },
    clearProgress() {
      this.$sessionStorage.remove(this.dataStorageKey);
      this.$sessionStorage.remove(this.progressStorageKey);
    },
    async completeWizard(to: string = '/') {
      let shallSendData = this.currentStepData.sendData;
      if (typeof shallSendData === 'function') {
        shallSendData = shallSendData(this.wizardData ?? {});
      }
      if (shallSendData) {
        const success = await this.callServiceFunction();
        if (!success) {
          return;
        }
      }
      this.$emit('completed', this.wizardData);
      let shallClearProgress = this.currentStepData.clearProgressAfterSend;
      if (typeof shallClearProgress === 'function') {
        shallClearProgress = shallClearProgress(this.wizardData ?? {});
      }
      if (shallClearProgress) {
        this.clearProgress();
      }
      this.$router.replace(to);
    },
    async backOneStep() {
      if (this.currentStepData.disablePreviousSteps) {
        return;
      }
      if (this.currentStepNr === 0) {
        return;
      }
      this.setStep(this.currentStepNr - 1);
    },
    async saveAndProceed() {
      if (this.wizardData && this.currentStepData.schema) {
        const isValid = this.wizardData.validateWithSchema(this.currentStepData.schema);
        if (!isValid) {
          return;
        }
      }
      let shallSendData = this.currentStepData.sendData;
      if (typeof shallSendData === 'function') {
        shallSendData = shallSendData(this.wizardData ?? {});
      }
      if (shallSendData) {
        const success = await this.callServiceFunction();
        if (!success) {
          return;
        }
      }
      if (this.currentStepData.clearProgressAfterSend) {
        this.clearProgress();
      } else {
        this.saveWizardData();
      }
      this.setStep(this.currentStepNr + 1);
    },
    onBreadcrumbClick(stepNr: number) {
      if (!this.isStepDisabled(stepNr)) {
        this.setStep(stepNr);
      }
    },
    checkAndClearProgressOnMount() {
      let shallClearProgress = this.currentStepData.clearProgressOnMounted;
      if (typeof shallClearProgress === 'function') {
        shallClearProgress = shallClearProgress(this.currentStepData ?? {});
      }
      if (shallClearProgress) {
        this.clearProgress();
      }
    },
    setStep(stepNr: number) {
      const steps = this.steps as StepData[];
      this.childSteps.forEach((step) => {
        step.errorResponse = undefined;
      });
      let computedStepNr = Math.min(steps.length - 1, stepNr);
      // This considers the last step to always be visible
      while (computedStepNr > 0 && computedStepNr < steps.length && !this.isStepVisible(steps[computedStepNr])) {
        if (stepNr >= this.currentStepNr) {
          computedStepNr += 1;
        } else {
          computedStepNr -= 1;
        }
      }
      this.$set(this, 'currentStepNr', computedStepNr);
      if (this.wizardData) {
        this.wizardData.errors = [];
      }
      if (this.currentStepNr > this.progressNr) {
        this.$set(this, 'progressNr', this.currentStepNr);
        // User moved further in the process, save the furthest step
        this.$sessionStorage.set(this.progressStorageKey, this.currentStepNr);
      }
      try {
        (this.$refs.wizardTitle as HTMLElement).scrollIntoView();
      } catch (e: any) {
        //
      }
      this.checkAndClearProgressOnMount();
    },
  },
});
