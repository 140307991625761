// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import AppInfoComponentResourceDTO from '../../dto/resource/AppInfoComponentResourceDTO';

export default abstract class AppInfoComponentResourceModel extends BaseModel<AppInfoComponentResourceDTO> {
  /**
  */
  get title() { return this.dto.title; }
  set title(value) { this.dto.title = value; }
  /**
  */
  get version() { return this.dto.version; }
  set version(value) { this.dto.version = value; }
  /**
  */
  get healthEndpoint() { return this.dto.healthEndpoint; }
  set healthEndpoint(value) { this.dto.healthEndpoint = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    this.dto.title = queries.title;
    this.dto.version = queries.version;
    this.dto.healthEndpoint = queries.healthEndpoint;
  }
}
