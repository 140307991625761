
















































import Vue from 'vue';

const md5 = require('md5');

export default Vue.extend({
  props: {
    value: { type: String, default: undefined },
    pageSize: { type: Number, default: 30 },

    showToolbar: { type: Boolean, default: false },
    writeToQuery: { type: Boolean, default: false },
  },
  data: () => ({
    isLoading: false,
    isLoadingMore: false,
    currentLoadedIndex: 0,
    listOfPublications: [] as any[],
    archiveVisible: false,
    issuId: undefined as undefined | string,
    selectedPublication: undefined,
  }),
  computed: {
    currentLanguage(): string {
      return this.$translation.get();
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.loadIssuById(this.value);
      },
    },
  },
  async mounted() {
    this.isLoading = true;
    const allIssuEmbeds = await this.loadMore();
    if (!this.value) {
      const [latestIssuEmbed] = allIssuEmbeds;
      const latestIssuId = latestIssuEmbed?.document?.name;
      this.onPublicationChanged(latestIssuId);
    }
    this.isLoading = false;
  },
  methods: {
    async loadMore() {
      this.isLoadingMore = true;
      const authOriginalValue = Vue.$axios.defaults.headers.common.Authorization;
      delete Vue.$axios.defaults.headers.common.Authorization;
      const issuEndpoint = `https://api.issuu.com/1_0?access=public&action=issuu.documents.list&apiKey=3g49x2esszskgxxi38xst7q0qds4hqng&documentSortBy=publishDate&format=json&pageSize=${this.pageSize}&startIndex=${this.currentLoadedIndex}&resultOrder=desc`;
      const sortedParams = issuEndpoint.replace(/https?:\/\/.+\?/g, '').replace(/[=]/g, '').split('&').sort();
      const issuSignatureBase = `38ilgh045z23leji6soi0h2k7ed40rkb${sortedParams.join('')}`;
      const issuSignature = md5(issuSignatureBase);
      const fullIssuEndpoint = `${issuEndpoint}&signature=${issuSignature}`;
      const response = await this.$service.get('', {
        baseURL: fullIssuEndpoint,
        withCredentials: false,
        headers: undefined,
      });
      Vue.$axios.defaults.headers.common.Authorization = authOriginalValue;
      // eslint-disable-next-line no-underscore-dangle
      const allIssus: any[] = (response.data as any)?.rsp?._content.result?._content ?? [];
      this.listOfPublications = [
        ...this.listOfPublications,
        ...allIssus.map((e: any) => ({
          caption: e.document?.title,
          id: e.document?.name,
        })),
      ];
      this.currentLoadedIndex += this.pageSize;
      this.isLoadingMore = false;
      return allIssus;
    },
    onPublicationChanged(id: string) {
      this.archiveVisible = false;
      this.$emit('input', id);
    },
    loadIssuById(id: string) {
      this.issuId = id;
      if (this.writeToQuery) {
        this.$routerUtils.updateQueryParams({ id });
      }
    },
  },
});
