// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import GuidLookupResourceDTO from '../../dto/resource/GuidLookupResourceDTO';

export default abstract class GuidLookupResourceModel extends BaseModel<GuidLookupResourceDTO> {
  /**
  * @type {uuid}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  */
  get caption() { return this.dto.caption; }
  set caption(value) { this.dto.caption = value; }
  /**
  */
  get details() { return this.dto.details; }
  set details(value) { this.dto.details = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    this.dto.id = queries.id;
    this.dto.caption = queries.caption;
    this.dto.details = queries.details;
  }
}
