// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import SubscriptionArticleResourceDTO from '../../dto/resource/SubscriptionArticleResourceDTO';

export default abstract class SubscriptionArticleResourceModel extends BaseModel<SubscriptionArticleResourceDTO> {
  /**
  * @type {uuid}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int32}
  */
  get subscriptionTemplateNumber() { return this.dto.subscriptionTemplateNumber; }
  set subscriptionTemplateNumber(value) { this.dto.subscriptionTemplateNumber = value; }
  /**
  * @type {uuid}
  */
  get itemId() { return this.dto.itemId; }
  set itemId(value) { this.dto.itemId = value; }
  /**
  * @type {int32}
  */
  get itemNumber() { return this.dto.itemNumber; }
  set itemNumber(value) { this.dto.itemNumber = value; }
  /**
  * @type {int32}
  */
  get itemPriceNumber() { return this.dto.itemPriceNumber; }
  set itemPriceNumber(value) { this.dto.itemPriceNumber = value; }
  /**
  * @type {int32}
  */
  get itemPriceType() { return this.dto.itemPriceType; }
  set itemPriceType(value) { this.dto.itemPriceType = value; }
  /**
  */
  get name() { return this.dto.name; }
  set name(value) { this.dto.name = value; }
  /**
  */
  get currency() { return this.dto.currency; }
  set currency(value) { this.dto.currency = value; }
  /**
  * @type {double}
  */
  get unitPrice() { return this.dto.unitPrice; }
  set unitPrice(value) { this.dto.unitPrice = value; }
  /**
  * @type {double}
  */
  get vatRate() { return this.dto.vatRate; }
  set vatRate(value) { this.dto.vatRate = value; }
  /**
  * @type {int32}
  */
  get durationInMonths() { return this.dto.durationInMonths; }
  set durationInMonths(value) { this.dto.durationInMonths = value; }
  /**
  * @type {int32}
  */
  get durationInWeeks() { return this.dto.durationInWeeks; }
  set durationInWeeks(value) { this.dto.durationInWeeks = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    this.dto.id = queries.id;
    value = Number.parseFloat(queries.subscriptionTemplateNumber);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.subscriptionTemplateNumber = value;
    this.dto.itemId = queries.itemId;
    value = Number.parseFloat(queries.itemNumber);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.itemNumber = value;
    value = Number.parseFloat(queries.itemPriceNumber);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.itemPriceNumber = value;
    value = Number.parseFloat(queries.itemPriceType);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.itemPriceType = value;
    this.dto.name = queries.name;
    this.dto.currency = queries.currency;
    value = Number.parseFloat(queries.unitPrice);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.unitPrice = value;
    value = Number.parseFloat(queries.vatRate);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.vatRate = value;
    value = Number.parseFloat(queries.durationInMonths);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.durationInMonths = value;
    value = Number.parseFloat(queries.durationInWeeks);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.durationInWeeks = value;
  }
}
