// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import IntegrationCallStepResourceDTO from '../../dto/resource/IntegrationCallStepResourceDTO';

export default abstract class IntegrationCallStepResourceModel extends BaseModel<IntegrationCallStepResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }
  /**
  */
  get creatorName() { return this.dto.creatorName; }
  set creatorName(value) { this.dto.creatorName = value; }
  /**
  * @type {date-time}
  */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }
  /**
  * @type {int64}
  */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }
  /**
  */
  get modifierName() { return this.dto.modifierName; }
  set modifierName(value) { this.dto.modifierName = value; }
  /**
  * @type {date-time}
  */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }
  /**
  * @type {int64}
  */
  get integrationCallId() { return this.dto.integrationCallId; }
  set integrationCallId(value) { this.dto.integrationCallId = value; }
  /**
  * @type {int64}
  */
  get targetAppSystemId() { return this.dto.targetAppSystemId; }
  set targetAppSystemId(value) { this.dto.targetAppSystemId = value; }
  /**
  */
  get targetAppSystemName() { return this.dto.targetAppSystemName; }
  set targetAppSystemName(value) { this.dto.targetAppSystemName = value; }
  /**
  */
  get name() { return this.dto.name; }
  set name(value) { this.dto.name = value; }
  /**
  * @type {int32}
  */
  get number() { return this.dto.number; }
  set number(value) { this.dto.number = value; }
  /**
  */
  get isSuccess() { return this.dto.isSuccess; }
  set isSuccess(value) { this.dto.isSuccess = value; }
  /**
  */
  get isOperative() { return this.dto.isOperative; }
  set isOperative(value) { this.dto.isOperative = value; }
  /**
  */
  get message() { return this.dto.message; }
  set message(value) { this.dto.message = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    value = Number.parseFloat(queries.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(queries.creatorId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.creatorId = value;
    this.dto.creatorName = queries.creatorName;
    this.dto.created = queries.created;
    value = Number.parseFloat(queries.modifierId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modifierId = value;
    this.dto.modifierName = queries.modifierName;
    this.dto.modified = queries.modified;
    value = Number.parseFloat(queries.integrationCallId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.integrationCallId = value;
    value = Number.parseFloat(queries.targetAppSystemId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.targetAppSystemId = value;
    this.dto.targetAppSystemName = queries.targetAppSystemName;
    this.dto.name = queries.name;
    value = Number.parseFloat(queries.number);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.number = value;
    value = queries.isSuccess === 'true' ? true : queries.isSuccess;
    value = queries.isSuccess === 'false' ? false : undefined;
    this.dto.isSuccess = value;
    value = queries.isOperative === 'true' ? true : queries.isOperative;
    value = queries.isOperative === 'false' ? false : undefined;
    this.dto.isOperative = value;
    this.dto.message = queries.message;
  }
}
